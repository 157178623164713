import axios from 'axios';
import Config from 'config/Config';

const API_URL = Config.API_URL;
                
export interface Notificacao {
  id: number;
  tipo: string;
  evento: string;
  pushName: string;
  criado_em: string;
  lido_em: string | null;
  lida: boolean;
  clinica_id: string;
  chat_id: string;
}

export const notificacaoService = {
  getNotificacoes: async (clinicaId: string): Promise<Notificacao[]> => {
    const response = await axios.get(`${API_URL}/api/notificacoes`, {
      params: { clinica_id: clinicaId },
    });
    return response.data;
  },

  marcarComoLida: async (id: number): Promise<Notificacao> => {
    const response = await axios.patch(`${API_URL}/api/notificacoes/${id}/read`);
    return response.data;
  },

  marcarTodasComoLidas: async (clinicaId: string): Promise<void> => {
    await axios.patch(`${API_URL}/api/notificacoes/read-all`, null, {
      params: { clinica_id: clinicaId },
    });
  },

  marcarChatComoLido: async (chatId: string): Promise<void> => {
    await axios.patch(`${API_URL}/api/notificacoes/read-chat/${chatId}`);
  },
};
