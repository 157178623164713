import React, { useState, useEffect, useCallback } from 'react';
import { Calendar, momentLocalizer, Views, SlotInfo } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/pt-br';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
  AgendasContainer,
  SidebarContainer,
  CalendarContainer,
  Footer,
  SearchInput,
  AgendaList,
  AgendaItem,
  AgendaItemProfile,
  AgendaItemName,
  AgendaItemStatus,
  CalendarWrapper,
  FiltersWrapper,
  RadioWrapper,
  RadioLabel,
  Flag,
  SidebarToggleButton,
  CalendarHeader,
  ProfissionalInfo,
  EventTitle,
  EventDescription,
  EventTime,
  TooltipContent,
  NewAppointmentButton,
  StyledInputLeftElement,
  SidebarHeader,
  SidebarCloseButton,
  ContentWrapper,
  LegendContainer,
  LegendItem,
  LegendColor,
  LegendText,
  AgendaViewContainer,
  AgendaDate,
  AgendaDateContent,
  AgendaTime,
  AgendaTitle,
  AgendaConvenio,
  AgendaHeader,
  DateRangeSelector,
  DateInput,
  QuickSelectButton,
  AgendaGrid,
  AgendaItemGrid,
  AgendaScrollContainer,
  BackButton,
  CalendarToolbar,
  CalendarToolbarButton,
  CalendarHeaderContent,
  StyledTabs,
  StyledTab,
  SearchEventContainer,
  SearchEventInput,
} from './AgendasModule.styles';
import { fetchProfissionais, Profissional } from 'services/profissionaisService';
import {
  fetchAgendasByProfissional,
  createAgenda,
  updateAgenda,
  deleteAgenda,
  Agenda,
} from 'services/agendasService';
import Config from 'config/Config';
import {
  FaCheckCircle,
  FaTimesCircle,
  FaCircle,
  FaSearch,
  FaCalendarAlt,
  FaTrash,
  FaSave,
  FaChevronLeft,
  FaChevronRight,
  FaChevronDown,
  FaArrowLeft,
} from 'react-icons/fa';
import axios from 'axios';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Input,
  Select,
  FormControl,
  FormLabel,
  useDisclosure,
  useToast,
  Tooltip,
  VStack,
  HStack,
  Text,
  Box,
  TabList,
  TabIndicator,
} from '@chakra-ui/react';
import CustomButton from 'components/CustomButton';
import { useAuth } from 'context/authContext';
import { motion, AnimatePresence } from 'framer-motion';
import AnotacoesView from './AnotacoesView';

moment.locale('pt-br');
const localizer = momentLocalizer(moment);
const API_URL = Config.API_URL;

interface CustomEvent {
  id: number;
  title: string;
  start: Date;
  end: Date;
  allDay: boolean;
  resource: Agenda;
  color?: string; // Tornando color opcional
  description: string;
}

const AgendasModule: React.FC = () => {
  const { userData } = useAuth();
  const [profissionais, setProfissionais] = useState<Profissional[]>([]);
  const [filteredProfissionais, setFilteredProfissionais] = useState<Profissional[]>([]);
  const [view, setView] = useState<typeof Views[keyof typeof Views] | 'ANOTACOES'>(Views.WEEK);
  const [events, setEvents] = useState<CustomEvent[]>([]);
  const [filterStatus, setFilterStatus] = useState('Todos');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentEvent, setCurrentEvent] = useState<Partial<Agenda & { data_confirmacao: Date | null }>>({});
  const [selectedProfissional, setSelectedProfissional] = useState<Profissional | null>(null);
  const [planosSaude, setPlanosSaude] = useState<string[]>([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [lastClick, setLastClick] = useState<number | null>(null);
  const [openDates, setOpenDates] = useState<{ [key: string]: boolean }>({});
  const [startDate, setStartDate] = useState(moment().startOf('week').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().endOf('week').format('YYYY-MM-DD'));
  const [searchEventTerm, setSearchEventTerm] = useState('');

  const handleAgendaClick = useCallback(
    async (profissional: Profissional) => {
      try {
        setSelectedProfissional(profissional);
        const response = await fetchAgendasByProfissional(profissional.id_profissional);
        const agendas = Array.isArray(response) ? response : [response];
        setEvents(
          agendas.map((agenda) => ({
            id: agenda.id_agenda,
            title: agenda.titulo,
            start: new Date(agenda.data_inicio),
            end: new Date(agenda.data_fim),
            allDay: false,
            resource: agenda,
            color: agenda.cor || '', // Garantindo que sempre seja uma string
            description: agenda.descricao || 'Sem descrição',
          }))
        );

        if (profissional.convenios) {
          setPlanosSaude(profissional.convenios);
        } else {
          setPlanosSaude([]);
        }
      } catch (error) {
        if (axios.isAxiosError(error) && error.response && error.response.status === 404) {
          setEvents([]);
        } else {
          console.error('Falha ao carregar agendas', error);
          toast({
            title: 'Erro',
            description: 'Falha ao carregar agendas. Veja detalhes no console.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      }
    },
    [setSelectedProfissional, setEvents, setPlanosSaude, toast]
  );

  useEffect(() => {
    const loadProfissionais = async () => {
      try {
        if (!userData?.id_clinica) {
          throw new Error('ID da clínica não encontrado.');
        }
        const data = await fetchProfissionais(userData.id_clinica);
        data.sort((a, b) => a.nome_profissional.localeCompare(b.nome_profissional));
        setProfissionais(data);
        setFilteredProfissionais(data);
        if (data.length > 0) {
          handleAgendaClick(data[0]);
        }
      } catch (error) {
        console.error('Falha ao carregar profissionais', error);
        toast({
          title: 'Erro',
          description: 'Falha ao carregar profissionais. Veja detalhes no console.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };
    if (userData) {
      loadProfissionais();
    }
  }, [userData, toast, handleAgendaClick]);

  useEffect(() => {
    let filtered = profissionais;
    if (filterStatus !== 'Todos') {
      filtered = filtered.filter((profissional) => profissional.status_profissional === filterStatus);
    }
    if (searchTerm) {
      filtered = filtered.filter((profissional) =>
        profissional.nome_profissional.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    setFilteredProfissionais(filtered);
  }, [profissionais, filterStatus, searchTerm]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilterStatus(e.target.value);
  };

  const handleSelectSlot = (slotInfo: SlotInfo) => {
    const now = Date.now();
    if (lastClick && now - lastClick < 300) {
      handleDoubleClickSlot(slotInfo);
      setLastClick(null);
    } else {
      setLastClick(now);
    }
  };

  const handleDoubleClickSlot = (slotInfo: SlotInfo) => {
    const data_inicio = slotInfo.start;
    const yesterday = new Date(Date.now() - 24 * 60 * 60 * 1000);
    if (data_inicio < yesterday) {
      toast({
        title: 'Erro',
        description: 'Não é possível criar um evento para um período passado.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    const data_fim = new Date(data_inicio.getTime() + 30 * 60000);
    setCurrentEvent({
      data_inicio,
      data_fim,
      titulo: '',
      descricao: '',
      cor: '#02348a',
      origem: 'manual',
      convenio: '',
      status_confirmacao: 'Pendente',
      fone_contato: '',
      profissional: {
        id_profissional: selectedProfissional?.id_profissional || 0,
        nome_profissional: selectedProfissional?.nome_profissional || '',
      },
    });
    setTimeout(() => {
      onOpen();
    }, 0);
  };

  const handleSelectEvent = (event: CustomEvent) => {
    setCurrentEvent(event.resource);
    onOpen();
  };

  const handleDeleteEvent = async () => {
    if (!currentEvent.id_agenda) return;
    const confirmed = window.confirm('Você tem certeza que deseja deletar este evento?');
    if (confirmed) {
      try {
        await deleteAgenda(currentEvent.id_agenda);
        setEvents(events.filter((e) => e.id !== currentEvent.id_agenda));
        onClose();
        toast({
          title: 'Sucesso',
          description: 'Evento deletado com sucesso.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } catch (error) {
        console.error('Falha ao deletar evento', error);
        toast({
          title: 'Erro',
          description: 'Falha ao deletar evento. Veja detalhes no console.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const handleSaveEvent = async () => {
    if (
      !selectedProfissional ||
      !currentEvent.titulo ||
      !currentEvent.data_inicio ||
      !currentEvent.data_fim
    ) {
      toast({
        title: 'Erro',
        description: 'Preencha todos os campos obrigatórios.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    if (currentEvent.data_inicio >= currentEvent.data_fim) {
      toast({
        title: 'Erro',
        description: 'Data de início deve ser menor que a data de fim.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    try {
      const now = new Date();
      const eventWithDefaults: Agenda = {
        ...currentEvent,
        cor: currentEvent.status_confirmacao === 'Confirmado' ? '#4e7900' : '#02348a',
        data_confirmacao: currentEvent.status_confirmacao === 'Confirmado' ? now : undefined,
        profissional: {
          id_profissional: selectedProfissional.id_profissional,
          nome_profissional: selectedProfissional.nome_profissional,
        },
      } as Agenda;
      let updatedOrNewEvent: Agenda;
      if (currentEvent.id_agenda) {
        updatedOrNewEvent = await updateAgenda(currentEvent.id_agenda, eventWithDefaults);
      } else {
        updatedOrNewEvent = await createAgenda(
          selectedProfissional.id_profissional,
          eventWithDefaults
        );
      }
      setEvents((prevEvents) => {
        const newEvents = currentEvent.id_agenda
          ? prevEvents.map((e) =>
              e.id === updatedOrNewEvent.id_agenda
                ? {
                    ...e,
                    title: updatedOrNewEvent.titulo,
                    start: new Date(updatedOrNewEvent.data_inicio),
                    end: new Date(updatedOrNewEvent.data_fim),
                    resource: updatedOrNewEvent,
                    color: updatedOrNewEvent.cor || '', // Garantindo que sempre seja uma string
                    description: updatedOrNewEvent.descricao || 'Sem descrição',
                  }
                : e
            )
          : [
              ...prevEvents,
              {
                id: updatedOrNewEvent.id_agenda,
                title: updatedOrNewEvent.titulo,
                start: new Date(updatedOrNewEvent.data_inicio),
                end: new Date(updatedOrNewEvent.data_fim),
                allDay: false,
                resource: updatedOrNewEvent,
                color: updatedOrNewEvent.cor || '', // Garantindo que sempre seja uma string
                description: updatedOrNewEvent.descricao || 'Sem descrição',
              },
            ];
        return newEvents;
      });
      onClose();
      toast({
        title: 'Sucesso',
        description: 'Evento salvo com sucesso.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Falha ao salvar evento', error);
      toast({
        title: 'Erro',
        description: 'Falha ao salvar evento. Veja detalhes no console.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const eventStyleGetter = (event: CustomEvent) => ({
    style: {
      backgroundColor: event.color || '#3174ad',
      borderRadius: '4px',
      opacity: 0.8,
      color: 'white',
      border: 'none',
      display: 'block',
      fontSize: '14px',
      padding: '2px 5px',
    },
  });

  const EventComponent = ({ event }: { event: CustomEvent }) => (
    <Tooltip
      label={
        <TooltipContent>
          <EventTitle>{event.title}</EventTitle>
          <EventDescription>{event.description}</EventDescription>
          <EventTime>
            {moment(event.start).format('HH:mm')} - {moment(event.end).format('HH:mm')}
          </EventTime>
          <Text>Convênio: {event.resource.convenio || 'N/A'}</Text>
          <Text>Status: {event.resource.status_confirmacao || 'N/A'}</Text>
          <Text>Telefone: {event.resource.fone_contato || 'N/A'}</Text>
        </TooltipContent>
      }
    >
      <Box className="rbc-event-content">{event.title}</Box>
    </Tooltip>
  );

  const formats = {
    eventTimeRangeFormat: () => '',
    dayHeaderFormat: (date: Date) => moment(date).format('ddd, D [de] MMMM'),
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleDateOpen = (date: string) => {
    setOpenDates(prev => ({ ...prev, [date]: !prev[date] }));
  };

  const groupEventsByDate = (events: CustomEvent[]) => {
    return events.reduce((acc, event) => {
      const date = moment(event.start).format('YYYY-MM-DD');
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(event);
      return acc;
    }, {} as { [key: string]: CustomEvent[] });
  };

  const handleQuickSelect = (period: 'thisWeek' | 'thisMonth' | 'nextWeek' | 'nextMonth') => {
    switch (period) {
      case 'thisWeek':
        setStartDate(moment().startOf('week').format('YYYY-MM-DD'));
        setEndDate(moment().endOf('week').format('YYYY-MM-DD'));
        break;
      case 'thisMonth':
        setStartDate(moment().startOf('month').format('YYYY-MM-DD'));
        setEndDate(moment().endOf('month').format('YYYY-MM-DD'));
        break;
      case 'nextWeek':
        setStartDate(moment().add(1, 'week').startOf('week').format('YYYY-MM-DD'));
        setEndDate(moment().add(1, 'week').endOf('week').format('YYYY-MM-DD'));
        break;
      case 'nextMonth':
        setStartDate(moment().add(1, 'month').startOf('month').format('YYYY-MM-DD'));
        setEndDate(moment().add(1, 'month').endOf('month').format('YYYY-MM-DD'));
        break;
    }
  };

  const handleBackToCalendar = () => {
    setView(Views.WEEK);
  };

  const sortEventsByStartTime = (events: CustomEvent[]) => {
    return events.sort((a, b) => a.start.getTime() - b.start.getTime());
  };

  const filteredEvents = events.filter(event => 
    moment(event.start).isBetween(startDate, endDate, null, '[]') &&
    event.title.toLowerCase().includes(searchEventTerm.toLowerCase())
  );

  const handleTabChange = (index: number) => {
    switch (index) {
      case 0:
        setView(Views.WEEK);
        break;
      case 1:
        setView(Views.AGENDA);
        break;
      case 2:
        setView('ANOTACOES' as any);
        break;
    }
  };

  const CustomToolbar = (toolbar: any) => {
    const goToBack = () => {
      toolbar.onNavigate('PREV');
    };

    const goToNext = () => {
      toolbar.onNavigate('NEXT');
    };

    const goToCurrent = () => {
      toolbar.onNavigate('TODAY');
    };

    return (
      <CalendarToolbar>
        <div>
          <CalendarToolbarButton onClick={goToBack}>Anterior</CalendarToolbarButton>
          <CalendarToolbarButton onClick={goToCurrent}>Hoje</CalendarToolbarButton>
          <CalendarToolbarButton onClick={goToNext}>Próximo</CalendarToolbarButton>
        </div>
        <strong>{toolbar.label}</strong>
        <div>
          <CalendarToolbarButton onClick={() => toolbar.onView(Views.MONTH)}>Mês</CalendarToolbarButton>
          <CalendarToolbarButton onClick={() => toolbar.onView(Views.WEEK)}>Semana</CalendarToolbarButton>
          <CalendarToolbarButton onClick={() => toolbar.onView(Views.DAY)}>Dia</CalendarToolbarButton>
          <CalendarToolbarButton onClick={() => toolbar.onView(Views.AGENDA)}>Agenda</CalendarToolbarButton>
        </div>
      </CalendarToolbar>
    );
  };

  const handleEventSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchEventTerm(e.target.value);
  };

  return (
    <AgendasContainer>
      <ContentWrapper>
        <SidebarContainer isOpen={isSidebarOpen}>
          <SidebarHeader>
            <SidebarCloseButton onClick={toggleSidebar}>
              <FaTimesCircle />
            </SidebarCloseButton>
          </SidebarHeader>
          <FiltersWrapper>
            <SearchInput>
              <StyledInputLeftElement>
                <FaSearch />
              </StyledInputLeftElement>
              <Input placeholder="Pesquisar por nome" onChange={handleSearch} />
            </SearchInput>
            <RadioWrapper>
              <Tooltip label="Todos">
                <RadioLabel>
                  <input
                    type="radio"
                    name="status"
                    value="Todos"
                    checked={filterStatus === 'Todos'}
                    onChange={handleStatusChange}
                  />
                  <FaCircle />
                </RadioLabel>
              </Tooltip>
              <Tooltip label="Ativo">
                <RadioLabel>
                  <input
                    type="radio"
                    name="status"
                    value="Ativo"
                    checked={filterStatus === 'Ativo'}
                    onChange={handleStatusChange}
                  />
                  <FaCheckCircle />
                </RadioLabel>
              </Tooltip>
              <Tooltip label="Inativo">
                <RadioLabel>
                  <input
                    type="radio"
                    name="status"
                    value="Inativo"
                    checked={filterStatus === 'Inativo'}
                    onChange={handleStatusChange}
                  />
                  <FaTimesCircle />
                </RadioLabel>
              </Tooltip>
            </RadioWrapper>
          </FiltersWrapper>
          <AgendaList>
            <AnimatePresence>
              {filteredProfissionais.map((profissional, index) => (
                <motion.div
                  key={profissional.id_profissional}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.2, delay: index * 0.05 }}
                >
                  <AgendaItem
                    onClick={() => handleAgendaClick(profissional)}
                    isSelected={selectedProfissional?.id_profissional === profissional.id_profissional}
                  >
                    <AgendaItemProfile
                      src={profissional.url_imagem ? `${API_URL}${profissional.url_imagem}` : '/perfil_default.jpeg'}
                      alt="Imagem de Perfil"
                    />
                    <AgendaItemName>{profissional.nome_profissional}</AgendaItemName>
                    <AgendaItemStatus>
                      <Flag $status={profissional.status_profissional.toLowerCase()} />
                    </AgendaItemStatus>
                  </AgendaItem>
                </motion.div>
              ))}
            </AnimatePresence>
          </AgendaList>
          <Footer>
            <Text fontSize="sm" color="gray.500">
              Total de profissionais: {filteredProfissionais.length}
            </Text>
          </Footer>
        </SidebarContainer>
        <CalendarContainer>
          <CalendarHeader>
            <CalendarHeaderContent>
              <HStack spacing={4}>
                <SidebarToggleButton onClick={toggleSidebar}>
                  {isSidebarOpen ? <FaChevronLeft /> : <FaChevronRight />}
                </SidebarToggleButton>
                <FaCalendarAlt size={24} />
                {selectedProfissional && (
                  <ProfissionalInfo>
                    <Text fontSize="md">{selectedProfissional.nome_profissional}</Text>
                    <Text fontSize="sm" color="gray.500">
                      {selectedProfissional.especialidades}
                    </Text>
                  </ProfissionalInfo>
                )}
              </HStack>
              <StyledTabs
                index={view === Views.WEEK ? 0 : view === Views.AGENDA ? 1 : 2}
                onChange={handleTabChange}
                variant="unstyled"
              >
                <TabList>
                  <StyledTab>Calendário</StyledTab>
                  <StyledTab>Agenda</StyledTab>
                  <StyledTab>Anotações</StyledTab>
                </TabList>
                <TabIndicator
                  mt="-1.5px"
                  height="2px"
                  bg="blue.500"
                  borderRadius="1px"
                />
              </StyledTabs>
              <NewAppointmentButton
                text="Novo Agendamento"
                onClick={() =>
                  handleDoubleClickSlot({
                    start: new Date(),
                    end: new Date(),
                    slots: [],
                    action: 'select',
                  })
                }
                leftIcon={<FaCalendarAlt />}
              />
            </CalendarHeaderContent>
          </CalendarHeader>
          <LegendContainer>
            <Tooltip label="Confirmado">
              <LegendItem>
                <LegendColor color="#4e7900" />
                <LegendText>Confirmado</LegendText>
              </LegendItem>
            </Tooltip>
            <Tooltip label="Pendente">
              <LegendItem>
                <LegendColor color="#02348a" />
                <LegendText>Pendente</LegendText>
              </LegendItem>
            </Tooltip>
          </LegendContainer>
          {view === Views.AGENDA && (
            <SearchEventContainer>
              <SearchEventInput>
                <StyledInputLeftElement>
                  <FaSearch />
                </StyledInputLeftElement>
                <Input
                  placeholder="Buscar consulta por título..."
                  value={searchEventTerm}
                  onChange={handleEventSearch}
                />
              </SearchEventInput>
            </SearchEventContainer>
          )}
          <CalendarWrapper>
            {view === 'ANOTACOES' ? (
              selectedProfissional ? (
                <AnotacoesView profissionalId={selectedProfissional.id_profissional} />
              ) : (
                <div>Selecione um profissional para ver as anotações.</div>
              )
            ) : view === Views.AGENDA ? (
              <AgendaViewContainer>
                <AgendaHeader>
                  <BackButton leftIcon={<FaArrowLeft />} onClick={handleBackToCalendar}>
                    Voltar
                  </BackButton>
                  <DateRangeSelector>
                    <DateInput
                      type="date"
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                    />
                    <DateInput
                      type="date"
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                    />
                  </DateRangeSelector>
                  <div>
                    <QuickSelectButton onClick={() => handleQuickSelect('thisWeek')}>Esta semana</QuickSelectButton>
                    <QuickSelectButton onClick={() => handleQuickSelect('thisMonth')}>Este mês</QuickSelectButton>
                    <QuickSelectButton onClick={() => handleQuickSelect('nextWeek')}>Próxima semana</QuickSelectButton>
                    <QuickSelectButton onClick={() => handleQuickSelect('nextMonth')}>Próximo mês</QuickSelectButton>
                  </div>
                </AgendaHeader>
                <AgendaGrid>
                  <span>Horário</span>
                  <span>Convênio</span>
                  <span>Título</span>
                </AgendaGrid>
                <AgendaScrollContainer>
                  {Object.entries(groupEventsByDate(filteredEvents))
                    .sort((a, b) => moment(a[0]).valueOf() - moment(b[0]).valueOf()) // Inverte a ordem para mostrar datas mais recentes primeiro
                    .map(([date, dateEvents]) => (
                      <div key={date}>
                        <AgendaDate onClick={() => toggleDateOpen(date)}>
                          {openDates[date] ? <FaChevronDown /> : <FaChevronRight />}
                          {moment(date).format('dddd, D [de] MMMM')}
                        </AgendaDate>
                        <AgendaDateContent isOpen={openDates[date]}>
                          {sortEventsByStartTime(dateEvents).map((event) => (
                            <Tooltip
                              key={event.id}
                              label={
                                <TooltipContent>
                                  <EventTitle>{event.title || '(Sem título)'}</EventTitle>
                                  <EventDescription>{event.description}</EventDescription>
                                  <EventTime>
                                    {moment(event.start).format('HH:mm')} - {moment(event.end).format('HH:mm')}
                                  </EventTime>
                                  <Text>Convênio: {event.resource.convenio || 'N/A'}</Text>
                                  <Text>Status: {event.resource.status_confirmacao || 'N/A'}</Text>
                                  <Text>Telefone: {event.resource.fone_contato || 'N/A'}</Text>
                                </TooltipContent>
                              }
                            >
                              <AgendaItemGrid 
                                onClick={() => handleSelectEvent(event)}
                                color={event.color || '#02348a'}
                              >
                                <AgendaTime>
                                  {moment(event.start).format('HH:mm')} - {moment(event.end).format('HH:mm')}
                                </AgendaTime>
                                <AgendaConvenio>{event.resource.convenio}</AgendaConvenio>
                                <AgendaTitle>{event.title || '(Sem título)'}</AgendaTitle>
                              </AgendaItemGrid>
                            </Tooltip>
                          ))}
                        </AgendaDateContent>
                      </div>
                    ))}
                </AgendaScrollContainer>
              </AgendaViewContainer>
            ) : (
              <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 'calc(100vh - 180px)' }}
                view={view}
                onView={(newView) => setView(newView)}
                selectable
                onSelecting={() => false}
                onSelectSlot={handleSelectSlot}
                onSelectEvent={handleSelectEvent}
                eventPropGetter={eventStyleGetter}
                components={{
                  event: EventComponent,
                  toolbar: CustomToolbar,
                }}
                formats={formats}
                min={new Date(new Date().setHours(6, 0, 0, 0))}
                max={new Date(new Date().setHours(20, 0, 0, 0))}
                messages={{
                  date: 'Data',
                  time: 'Hora',
                  event: 'Evento',
                  allDay: 'Dia inteiro',
                  week: 'Semana',
                  work_week: 'Semana de trabalho',
                  day: 'Dia',
                  month: 'Mês',
                  previous: 'Anterior',
                  next: 'Próximo',
                  yesterday: 'Ontem',
                  tomorrow: 'Amanhã',
                  today: 'Hoje',
                  agenda: 'Agenda',
                  noEventsInRange: 'Não há eventos neste período',
                  showMore: (total: number) => `+ Ver mais (${total})`,
                }}
              />
            )}
          </CalendarWrapper>
        </CalendarContainer>
      </ContentWrapper>
      <Modal isOpen={isOpen} onClose={onClose} size="md">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader style={{ color: 'white' }}>
            {currentEvent.id_agenda ? 'Editar Agendamento' : 'Novo Agendamento'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl isRequired>
                <FormLabel>Título</FormLabel>
                <Input
                  placeholder="Título do agendamento"
                  value={currentEvent.titulo || ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setCurrentEvent({ ...currentEvent, titulo: e.target.value })
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>Observações</FormLabel>
                <Input
                  placeholder="Observações adicionais"
                  value={currentEvent.descricao || ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setCurrentEvent({ ...currentEvent, descricao: e.target.value })
                  }
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Convênio</FormLabel>
                <Select
                  value={currentEvent.convenio || ''}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    setCurrentEvent({ ...currentEvent, convenio: e.target.value })
                  }
                  placeholder="Selecione um convênio"
                >
                  {planosSaude.map((plano, index) => (
                    <option key={index} value={plano}>
                      {plano}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Status de Confirmação</FormLabel>
                <Select
                  value={currentEvent.status_confirmacao || ''}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    setCurrentEvent({ 
                      ...currentEvent, 
                      status_confirmacao: e.target.value,
                      cor: e.target.value === 'Confirmado' ? '#4e7900' : '#02348a',
                      data_confirmacao: e.target.value === 'Confirmado' ? new Date() : undefined
                    })
                  }
                  placeholder="Selecione o status"
                >
                  <option value="Pendente">Pendente</option>
                  <option value="Confirmado">Confirmado</option>
                </Select>
              </FormControl>
              <FormControl>
                <FormLabel>Telefone de Contato</FormLabel>
                <Input
                  placeholder="Telefone de contato"
                  value={currentEvent.fone_contato || ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setCurrentEvent({ ...currentEvent, fone_contato: e.target.value })
                  }
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Data e Hora de Início</FormLabel>
                <Input
                  type="datetime-local"
                  value={
                    currentEvent.data_inicio
                      ? moment(currentEvent.data_inicio).format('YYYY-MM-DDTHH:mm')
                      : ''
                  }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const data_inicio = new Date(e.target.value);
                    const data_fim = new Date(data_inicio.getTime() + 30 * 60000);
                    setCurrentEvent({ ...currentEvent, data_inicio, data_fim });
                  }}
                />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Data e Hora de Término</FormLabel>
                <Input
                  type="datetime-local"
                  value={
                    currentEvent.data_fim
                      ? moment(currentEvent.data_fim).format('YYYY-MM-DDTHH:mm')
                      : ''
                  }
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setCurrentEvent({ ...currentEvent, data_fim: new Date(e.target.value) })
                  }
                />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <HStack spacing={3}>
              {currentEvent.id_agenda && (
                <CustomButton
                  text="Excluir"
                  colorScheme="red"
                  onClick={handleDeleteEvent}
                  leftIcon={<FaTrash />}
                />
              )}
              <CustomButton text="Cancelar" colorScheme="gray" onClick={onClose} />
              <CustomButton
                text="Salvar"
                colorScheme="blue"
                onClick={handleSaveEvent}
                leftIcon={<FaSave />}
              />
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </AgendasContainer>
  );
};

export default AgendasModule;
