import axios from 'axios';
import Config from 'config/Config';


const API_URL = Config.API_URL; // Acessando a variável de ambiente

export interface Procedimento {
  id_procedimento: number;
  procedimento: string;
  ds_procedimento?: string;
  tempo_procedimento?: string;
  profissionalId: number;
  dt_create?: string;
}

export interface Profissional {
  id_profissional: number;
  nome_profissional: string;
  email_profissional: string;
  url_imagem: string;
  status_profissional: string;
  especialidades: string[];
  convenios?: string[];
  fone_profissional: string;
  clinica: string;
  id_agenda: number;
  procedimentos?: Procedimento[];
  ausencia_start: Date;
  ausencia_end: Date;
  valor_procedimento?: string;
  ausencias?: Array<{ inicio: Date | null; fim: Date | null }>;
}

// Função para buscar todos os profissionais de uma clínica específica
export const fetchProfissionais = async (id_clinica: string) => {
  try {
    const response = await axios.get<Profissional[]>(`${API_URL}/api/profissionais/clinica/${id_clinica}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching profissionais:', error);
    throw new Error('Failed to fetch profissionais.');
  }
};

// Função para buscar um profissional por ID
export const fetchProfissionalById = async (id: string) => {
  try {
    const response = await axios.get<Profissional>(`${API_URL}/api/profissionais/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching profissional with id ${id}:`, error);
    throw new Error('Failed to fetch profissional.');
  }
};

// Função para criar um novo profissional
export const createProfissional = async (profissional: Partial<Profissional>) => {
  try {
    const response = await axios.post<Profissional>(`${API_URL}/api/profissionais`, profissional);
    
    return response.data;
  } catch (error) {
    console.error('Error creating profissional:', error);
    throw new Error('Failed to save profissional.');
  }
};

// Função para atualizar um profissional existente
export const updateProfissional = async (id: string, profissional: Profissional) => {
  try {
    const response = await axios.patch<Profissional>(`${API_URL}/api/profissionais/${id}`, profissional);
   
    return response.data;
  } catch (error) {
    console.error(`Error updating profissional with id ${id}:`, error);
    throw new Error('Failed to update profissional.');
  }
};

// Função para deletar um profissional
export const deleteProfissional = async (id: string) => {
  try {
    await axios.delete(`${API_URL}/api/profissionais/${id}`);
  } catch (error) {
    console.error(`Error deleting profissional with id ${id}:`, error);
    throw new Error('Failed to delete profissional.');
  }
};

// Função para buscar profissionais por clínica
export const fetchProfissionaisByClinica = async (id_clinica: string) => {
  try {
    const response = await axios.get<Profissional[]>(`${API_URL}/api/profissionais/clinica/${id_clinica}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching profissionais for clinica with id ${id_clinica}:`, error);
    throw new Error('Failed to fetch profissionais for clinica.');
  }
};
