import React, { useState, useEffect } from 'react';
import { fetchHelps, createHelp, Help, CreateHelpDto } from 'services/helpService';
import CustomButton from 'components/CustomButton';
import { Box, Heading, Text, Input, Grid, GridItem, AspectRatio, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, FormControl, FormLabel, HStack, Flex, VStack } from '@chakra-ui/react';
import { FaPlus, FaPlay } from 'react-icons/fa';
import { motion } from 'framer-motion';

const HelpModule: React.FC = () => {
  const [helps, setHelps] = useState<Help[]>([]);
  const [newHelp, setNewHelp] = useState<CreateHelpDto>({ indice: 0, titulo: '', descricao: '', url_video: '' });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedHelp, setSelectedHelp] = useState<Help | null>(null);

  useEffect(() => {
    const loadHelps = async () => {
      try {
        const data = await fetchHelps();
        setHelps(data);
      } catch (error) {
        console.error('Failed to fetch helps', error);
      }
    };

    loadHelps();
  }, []);

  const handleNewHelp = () => {
    onOpen();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewHelp({
      ...newHelp,
      [name]: name === 'indice' ? Number(value) : value,
    });
  };

  const handleCreateHelp = async () => {
    try {
      const createdHelp = await createHelp(newHelp);
      setHelps([...helps, createdHelp]);
      onClose();
      setNewHelp({ indice: 0, titulo: '', descricao: '', url_video: '' });
    } catch (error) {
      console.error('Failed to create help', error);
    }
  };

  const getEmbedUrl = (url: string) => {
    const videoId = url.split('v=')[1]?.split('&')[0];
    return videoId ? `https://www.youtube.com/embed/${videoId}` : '';
  };

  const filteredHelps = helps.filter(help => 
    help.titulo.toLowerCase().includes(searchTerm.toLowerCase()) ||
    help.descricao.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const bgColor = '#f0f4fa';
  const textColor = '#333333';
  const cardBgColor = 'white';
  const accentColor = '#3182ce';

  return (
    <Flex direction="column" minH="100vh" w="100%" bg={bgColor} color={textColor}>
      <Box w="100%" p={4} bg="white" boxShadow="sm" position="sticky" top={0} zIndex={10}>
        <Flex alignItems="center" justifyContent="center" flexDirection="column">
          <Heading size="lg" color={accentColor} mb={4}>Vídeos de Ajuda</Heading>
          <HStack spacing={4} width="100%" justifyContent="center">
            <Input 
              placeholder="Vídeos de Ajuda" 
              value={searchTerm} 
              onChange={(e) => setSearchTerm(e.target.value)}
              bg="white"
              border="1px solid"
              borderColor="gray.200"
              _placeholder={{ color: 'gray.400' }}
              maxWidth="400px"
            />
            <CustomButton text="New" colorScheme="blue" icon={<FaPlus />} onClick={handleNewHelp} />
          </HStack>
        </Flex>
      </Box>
      <Box flex="1" overflowY="auto" p={8}>
        <Grid templateColumns="repeat(auto-fill, minmax(250px, 1fr))" gap={6}>
          {filteredHelps.map(help => (
            <motion.div key={help.id_help} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
              <GridItem 
                bg={cardBgColor} 
                borderRadius="md" 
                overflow="hidden" 
                boxShadow="md"
                cursor="pointer"
                onClick={() => setSelectedHelp(help)}
              >
                <AspectRatio ratio={16 / 9}>
                  <Box
                    bgImage={`url(https://img.youtube.com/vi/${help.url_video.split('v=')[1]?.split('&')[0]}/0.jpg)`}
                    bgSize="cover"
                    bgPosition="center"
                  >
                    <Flex 
                      alignItems="center" 
                      justifyContent="center" 
                      w="100%" 
                      h="100%" 
                      bg="rgba(0,0,0,0.3)"
                      opacity={0}
                      _hover={{ opacity: 1 }}
                      transition="opacity 0.2s"
                    >
                      <FaPlay size="3rem" color="white" />
                    </Flex>
                  </Box>
                </AspectRatio>
                <VStack align="start" p={4} spacing={2}>
                  <Heading size="sm" color={accentColor}>{help.titulo}</Heading>
                  <Text fontSize="xs" noOfLines={2} color={textColor}>{help.descricao}</Text>
                </VStack>
              </GridItem>
            </motion.div>
          ))}
        </Grid>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent bg={cardBgColor} color={textColor}>
          <ModalHeader color={accentColor}>New Help Video</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Index</FormLabel>
              <Input name="indice" type="number" value={newHelp.indice} onChange={handleInputChange} />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Title</FormLabel>
              <Input name="titulo" value={newHelp.titulo} onChange={handleInputChange} />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Description</FormLabel>
              <Input name="descricao" value={newHelp.descricao} onChange={handleInputChange} />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Video URL</FormLabel>
              <Input name="url_video" value={newHelp.url_video} onChange={handleInputChange} />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <CustomButton text="Cancel" colorScheme="gray" mr={3} onClick={onClose} />
            <CustomButton text="Save" colorScheme="blue" onClick={handleCreateHelp} />
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={!!selectedHelp} onClose={() => setSelectedHelp(null)} size="4xl">
        <ModalOverlay />
        <ModalContent bg={cardBgColor} color={textColor}>
          <ModalHeader color={accentColor}>{selectedHelp?.titulo}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <AspectRatio ratio={16 / 9}>
              {selectedHelp && (
                <iframe
                  title={selectedHelp.titulo}
                  src={getEmbedUrl(selectedHelp.url_video)}
                  allowFullScreen
                />
              )}
            </AspectRatio>
            <Text mt={4}>{selectedHelp?.descricao}</Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default HelpModule;