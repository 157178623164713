import axios from 'axios';
import Config from 'config/Config';
import { getMessageByStatus } from 'helpers/messages.helper';

export interface WhatsAppSession {
  id: string;
  name: string | null;
  status: string;
  phoneNumber: string | null;
  sessionData: string;
  createdAt: string;
  clinica: {
    id_clinica: string;
    nome_clinica: string | null;
    nome_do_chatbot: string | null;
    endereco_clinica: string;
    site_clinica: string;
    telefone_clinica: string;
    horario_duteis: string | null;
    dt_create: string;
  };
  chats: Chat[]; // Ensure chats are included
}

export interface Chat {
  id: string;
  userName: string;
  userImage: string;
  messages: ChatMessage[];
  status: 'open' | 'closed';
  classification: 'ASSIGNED' | 'PENDING';
  tags: string[];
  session: WhatsAppSession;
  ticket: string | null;
  userId: string;
  createdAt: string;
}

export interface ChatMessage {
  id: string;
  from: string;
  to: string;
  body: string;
  pushName: string;
  timestamp: string; // Ensure this matches the format received from the backend
  chat: { id: string };
}

class WhatsAppMultTicketService {
  async updateChatStatus(chatId: string, status: 'open' | 'closed') {
    try {
      const response = await axios({
        url: `${Config.API_URL}/api/whatsapp/chat/${chatId}/status`,
        method: 'PATCH',
        timeout: Config.TIMEOUT_REQUEST,
        headers: Config.HEADER_REQUEST,
        data: { status },
      });
      return response.data;
    } catch (error: any) {
      return this.handleError(error);
    }
  }

  async updateChatClassification(chatId: string, classification: 'ASSIGNED' | 'PENDING', tags: string[]) {
    try {
      const response = await axios({
        url: `${Config.API_URL}/api/whatsapp/chat/${chatId}/classification`,
        method: 'PATCH',
        timeout: Config.TIMEOUT_REQUEST,
        headers: Config.HEADER_REQUEST,
        data: {
          classification,
          tags,
        },
      });
      return response.data;
    } catch (error: any) {
      return this.handleError(error);
    }
  }

  async sendMessage(chatId: string, message: string) {
    try {
      const response = await axios({
        url: `${Config.API_URL}/api/whatsapp/chat/${chatId}/message`,
        method: 'POST',
        timeout: Config.TIMEOUT_REQUEST,
        headers: Config.HEADER_REQUEST,
        data: {
          message,
        },
      });
      return response.data;
    } catch (error: any) {
      return this.handleError(error);
    }
  }

  async getChatsByClinic(clinicId: string): Promise<Chat[]> {
    try {
      const response = await axios({
        url: `${Config.API_URL}/api/whatsapp/chats/${clinicId}`,
        method: 'GET',
        timeout: Config.TIMEOUT_REQUEST,
        headers: Config.HEADER_REQUEST,
      });
      // Ensure messages are sorted in chronological order
      const chats: Chat[] = response.data.map((chat: Chat) => ({
        ...chat,
        session: chat.session,
        messages: chat.messages.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()),
      }));
      return chats;
    } catch (error: any) {
      return this.handleError(error);
    }
  }

  async getProfilePicture(sessionId: string | undefined, userId: string): Promise<string | null> {
    if (!sessionId) {
      console.error('Session ID is undefined when fetching profile picture for user:', userId);
      return null;
    }
  
    try {
      console.log(`Fetching profile picture for session ${sessionId} and user ${userId}`);
      const response = await axios({
        url: `${Config.API_URL}/api/whatsapp/${sessionId}/profile-picture/${userId}`,
        method: 'GET',
        timeout: Config.TIMEOUT_REQUEST,
        headers: Config.HEADER_REQUEST,
      });
      console.log('Profile picture response:', response.data);
      return response.data.url;
    } catch (error: any) {
      console.error('Error fetching profile picture:', error.response?.status, error.response?.data);
      return null;
    }
  }

  async updateChatTags(chatId: string, tag: string) {
    try {
      const response = await axios({
        url: `${Config.API_URL}/api/whatsapp/chat/${chatId}/tags`,
        method: 'PATCH',
        timeout: Config.TIMEOUT_REQUEST,
        headers: Config.HEADER_REQUEST,
        data: { tag },
      });
      return response.data;
    } catch (error: any) {
      return this.handleError(error);
    }
  }
  
  async clearChatTags(chatId: string) {
    try {
      const response = await axios({
        url: `${Config.API_URL}/api/whatsapp/chat/${chatId}/tags/clear`,
        method: 'PATCH',
        timeout: Config.TIMEOUT_REQUEST,
        headers: Config.HEADER_REQUEST,
      });
      return response.data;
    } catch (error: any) {
      return this.handleError(error);
    }
  }

  private handleError(error: any): Promise<any> {
    console.error('Error during process:', error);
    const status = error.response?.status;
    const message = error.response?.data?.message || getMessageByStatus(status) || 'Ocorreu um erro desconhecido.';
    return Promise.reject(new Error(message));
  }
}

const whatsAppMultTicketService = new WhatsAppMultTicketService();
export default whatsAppMultTicketService;