import styled, { keyframes } from 'styled-components';
import { Box, useColorModeValue, Modal} from '@chakra-ui/react';

// Animação para o efeito de hover nos ícones
const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
`;

export const HeaderContainer = styled.header`
  width: 100%;
  background-color: ${({ theme }) => useColorModeValue(theme.colors.header.light, theme.colors.header.dark)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 70px;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 0 10px;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  img {
    width: 45px;
    height: 45px;
  }

  span {
    font-family: ${({ theme }) => theme.fonts.heading};
    font-size: 24px;
    font-weight: 700;
    color: white;

    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const MenuIcon = styled.div`
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    animation: ${pulseAnimation} 0.5s ease-in-out infinite;
  }
`;

export const Icon = styled.div`
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    animation: ${pulseAnimation} 0.5s ease-in-out infinite;
    filter: brightness(1.2);
  }
`;

export const RightIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: 768px) {
    gap: 15px;
  }

  &:hover ${Icon}:not(:hover) {
    opacity: 0.5;
    filter: blur(1px);
  }
`;

export const MenuItem = styled.div`
  padding: 12px 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 15px;
  transition: all 0.3s ease;
  position: relative;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    transform: translateX(5px);
  }

  svg {
    font-size: 20px;
    color: ${({ theme }) => theme.colors.menu.icon};
  }
`;
export const MenuItemTooltip = styled.div<{ $isVisible: boolean }>`
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 1003;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    border-width: 5px 5px 5px 0;
    border-style: solid;
    border-color: transparent rgba(0, 0, 0, 0.8) transparent transparent;
  }
`;

export const AlertBadge = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
  background-color: red;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;

  img {
    border-radius: 50%;
    width: 45px;
    height: 45px;
    border: 2px solid white;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.1);
    }
  }
`;

export const Sidebar = styled.div<{ $isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: ${({ $isOpen }) => ($isOpen ? '0' : '-300px')};
  width: 300px;
  height: 100vh;
  background-color: #f8f9fa;
  color: ${({ theme }) => theme.colors.menu.text.light};
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
  transition: left 0.3s ease-in-out;
  z-index: 1001;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const SidebarOverlay = styled.div<{ $isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
  visibility: ${({ $isOpen }) => ($isOpen ? 'visible' : 'hidden')};
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  z-index: 1000;
`;

export const IconWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

export const MenuTitle = styled.div`
  padding: 10px 20px 5px; // Reduzido o padding superior
  color: #7f8c8d;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const SettingsBox = styled(Box)`
  position: absolute;
  top: 70px;
  right: 20px;
  z-index: 1002;
  background-color: ${({ theme }) => theme.colors.menu.background.light};
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`;

export const TrialContainer = styled.div`
  width: 100%;
  background-color: #C84801;
  color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`;

export const CustomTooltip = styled.div<{ $isVisible: boolean }>`
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
  visibility: ${({ $isVisible }) => ($isVisible ? 'visible' : 'hidden')};
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 1003;

  &::before {
    content: '';
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    border-width: 0 5px 5px 5px;
    border-style: solid;
    border-color: transparent transparent rgba(0, 0, 0, 0.8) transparent;
  }
`;

export const TrialTextLeft = styled.div`
  flex: 1;
`;
export const MenuSection = styled.div`
  margin-bottom: 10px;
`;
export const TrialTextCenter = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
`;

export const SidebarTooltip = styled(CustomTooltip)`
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 10px;
  z-index: 1010; // Aumente este valor se necessário
  
  &::before {
    top: 50%;
    left: -5px;
    transform: translateY(-50%);
    border-width: 5px 5px 5px 0;
    border-color: transparent rgba(0, 0, 0, 0.8) transparent transparent;
  }
`;

export const NotificationModal = styled(Modal)`
  .chakra-modal__content {
    max-width: 500px;
    width: 100%;
  }
`;

export const NotificationDetailModal = styled(Modal)`
  .chakra-modal__content {
    max-width: 400px;
    width: 100%;
  }
`;

export const NotificationDropdownContainer = styled.div`
  position: relative;
`;

export const NotificationIcon = styled.div`
  cursor: pointer;
  position: relative;
`;

export const DropdownContent = styled.div`
  position: absolute;
  right: 0;
  top: 100%;
  width: 350px;
  max-height: 500px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  overflow: hidden;
`;
export const NotificationActions = styled.div`
  margin-bottom: 20px;
`;

export const NotificationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #eee;

  h2 {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
  }

  div {
    display: flex;
    gap: 10px;
  }
`;

export const NotificationList = styled.div`
  max-height: 400px;
  overflow-y: auto;
`;

export const NotificationItem = styled.div<{ $isRead: boolean }>`
  display: flex;
  align-items: center;
  padding: 12px 15px;
  cursor: pointer;
  transition: background-color 0.2s;
  background-color: ${props => props.$isRead ? 'white' : '#f0f2f5'};

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const NotificationIconWrapper = styled.div`
  font-size: 24px;
  margin-right: 12px;
`;

export const NotificationContent = styled.div`
  flex: 1;
`;

export const NotificationGroup = styled.div`
  border-bottom: 1px solid #eee;
`;

export const NotificationGroupHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background-color: #f7f7f7;

  h3 {
    font-weight: bold;
    font-size: 14px;
    margin: 0;
  }
`;

export const MarkAsReadButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 5px;

  &:hover {
    text-decoration: underline;
  }
`;

export const ClearAllButton = styled(MarkAsReadButton)`
  color: ${({ theme }) => theme.colors.danger};
`;

export const EmptyNotification = styled.div`
  padding: 20px;
  text-align: center;
  color: #888;
`;

export const NotificationTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 5px;
`;

export const NotificationMessage = styled.div`
  font-size: 13px;
  color: #333;
  margin-bottom: 5px;
`;

export const NotificationTime = styled.div`
  font-size: 11px;
  color: #888;
`;

export const NotificationFooter = styled.div`
  padding: 15px;
  text-align: center;
  border-top: 1px solid #eee;
`;

export const ViewAllButton = styled.button`
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
`;
