import axios from 'axios';
import Config from 'config/Config';

const API_URL = Config.API_URL; // Usar a URL base corretamente

export interface Clinica {
  id_clinica: string;
  nome_clinica?: string;
  nome_do_chatbot?: string;
  site_clinica?: string;
  telefone_clinica?: string;
  dt_create: Date;
  cep?: string;
  logradouro?: string;
  complemento?: string;
  bairro?: string;
  localidade?: string;
  uf?: string;
  convenios?: string[];
  especialidades?: string[];
  expediente_dias?: string;
  expediente_horarios?: string;
  cnpj?: string;
  welcome?: string;
  ibge?: string;
}

export interface UpdateClinicaDto {
  nome_clinica?: string;
  nome_do_chatbot?: string;
  endereco_clinica?: string;
  site_clinica?: string;
  telefone_clinica?: string;
  cep?: string;
  logradouro?: string;
  complemento?: string;
  unidade?: string;
  bairro?: string;
  localidade?: string;
  uf?: string;
  convenios?: string[];
  especialidades?: string[];
  expediente_dias?: string;
  expediente_horarios?: string;
  cnpj?: string;
  welcome?: string;
  ibge?: string;
}

// Função para buscar todas as clínicas
export const fetchClinicas = async (): Promise<Clinica[]> => {
  try {
    const response = await axios.get<Clinica[]>(`${API_URL}/api/clinicas`);
    return response.data;
  } catch (error) {
    console.error('Error fetching clinicas:', error);
    throw new Error('Failed to fetch clinicas.');
  }
};

// Função para buscar uma clínica por ID
export const fetchClinicaById = async (id: string): Promise<Clinica> => {
  try {
    const response = await axios.get<Clinica>(`${API_URL}/api/clinicas/${id}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching clinica with id ${id}:`, error);
    throw new Error('Failed to fetch clinica.');
  }
};

// Função para atualizar uma clínica existente
export const updateClinica = async (id: string, updateClinicaDto: UpdateClinicaDto): Promise<Clinica> => {
  try {
    const response = await axios.put<Clinica>(`${API_URL}/api/clinicas/${id}`, updateClinicaDto);
    return response.data;
  } catch (error) {
    console.error(`Error updating clinica with id ${id}:`, error);
    throw new Error('Failed to update clinica.');
  }
};
