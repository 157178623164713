import React, { useEffect, useState } from 'react';
import { Box, CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import LeftContainer from './LeftContainer';
import RightContainer from './RightContainer';
import { Chat, ChatMessage } from './types';
import { useAuth } from '../../context/authContext';
import whatsAppMultTicketService from 'services/whatsAppMultTicketService';
import { useSocket } from 'context/SocketContext';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useSnackbar } from 'notistack';

const theme = createTheme({
  palette: {
    primary: {
      main: '#8DA4CC',
    },
  },
});

const WPComponent: React.FC = () => {
  const { userData } = useAuth();
  const { socket } = useSocket();
  const [chats, setChats] = useState<{
    list: Chat[];
    current: string | null;
  }>({
    list: [],
    current: null,
  });

  const [tabIndex, setTabIndex] = useState<number>(0);
  const [subTabIndex, setSubTabIndex] = useState<number>(1);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  // ... (rest of the state and effects remain the same)


  useEffect(() => {
    const fetchChats = async () => {
      if (userData?.id_clinica) {
        const chats: Chat[] = await whatsAppMultTicketService.getChatsByClinic(userData.id_clinica);
        setChats({ list: chats, current: null });
      }
    };

    const loadSavedData = async () => {
      const savedChats = await AsyncStorage.getItem('chats');
      const savedCurrent = await AsyncStorage.getItem('currentChat');
      const savedTabIndex = await AsyncStorage.getItem('tabIndex');
      const savedSubTabIndex = await AsyncStorage.getItem('subTabIndex');
      const savedSidebarState = await AsyncStorage.getItem('sidebarOpen');

      if (savedChats) {
        setChats({ list: JSON.parse(savedChats), current: savedCurrent });
      }

      if (savedTabIndex) setTabIndex(parseInt(savedTabIndex));
      if (savedSubTabIndex) setSubTabIndex(parseInt(savedSubTabIndex));
      if (savedSidebarState) setIsSidebarOpen(JSON.parse(savedSidebarState));
    };

    fetchChats();
    loadSavedData();
  }, [userData]);

  useEffect(() => {
    if (socket) {
      socket.on('newMessage', (message: { chatId: string, message: ChatMessage }) => {
        setChats((prevState) => {
          const chatIndex = prevState.list.findIndex(chat => chat.id === message.chatId);
          if (chatIndex === -1) {
            return prevState;
          }
          const updatedChat = { ...prevState.list[chatIndex] };
          const messageExists = updatedChat.messages.some(msg => msg.id === message.message.id);
          if (!messageExists) {
            updatedChat.messages.push(message.message);
            const updatedChats = [...prevState.list];
            updatedChats[chatIndex] = updatedChat;
            AsyncStorage.setItem('chats', JSON.stringify(updatedChats));
            return { ...prevState, list: updatedChats };
          }
          return prevState;
        });
      });

      socket.on('chatStatusUpdate', (update: any) => {
        setChats((prevState) => {
          const chatIndex = prevState.list.findIndex(chat => chat.id === update.chatId);
          if (chatIndex !== -1) {
            const updatedChat = { ...prevState.list[chatIndex], status: update.status as 'open' | 'closed' };
            const updatedChats = [...prevState.list];
            updatedChats[chatIndex] = updatedChat;
            AsyncStorage.setItem('chats', JSON.stringify(updatedChats));
            return { ...prevState, list: updatedChats };
          }
          return prevState;
        });
      });

      socket.on('chatClassificationUpdate', (update: any) => {
        setChats((prevState) => {
          const chatIndex = prevState.list.findIndex(chat => chat.id === update.chatId);
          if (chatIndex !== -1) {
            const updatedChat = { ...prevState.list[chatIndex], classification: update.classification as 'ASSIGNED' | 'PENDING' };
            const updatedChats = [...prevState.list];
            updatedChats[chatIndex] = updatedChat;
            AsyncStorage.setItem('chats', JSON.stringify(updatedChats));
            return { ...prevState, list: updatedChats };
          }
          return prevState;
        });
      });

      socket.on('newChat', (chat: Chat) => {
        setChats((prevState) => {
          const chatExists = prevState.list.some(c => c.id === chat.id);
          if (!chatExists) {
            const updatedChats = [...prevState.list, chat];
            AsyncStorage.setItem('chats', JSON.stringify(updatedChats));
            return { ...prevState, list: updatedChats };
          }
          return prevState;
        });
      });

      socket.on('chatTagsUpdate', (update: { chatId: string; tags: string[] }) => {
        setChats((prevState) => {
          const chatIndex = prevState.list.findIndex(chat => chat.id === update.chatId);
          if (chatIndex !== -1) {
            const updatedChat = { ...prevState.list[chatIndex], tags: update.tags };
            const updatedChats = [...prevState.list];
            updatedChats[chatIndex] = updatedChat;
            AsyncStorage.setItem('chats', JSON.stringify(updatedChats));
            return { ...prevState, list: updatedChats };
          }
          return prevState;
        });
      });

      return () => {
        socket.off('newMessage');
        socket.off('chatStatusUpdate');
        socket.off('chatClassificationUpdate');
        socket.off('newChat');
        socket.off('chatTagsUpdate');
      };
    }
  }, [socket]);

  useEffect(() => {
    AsyncStorage.setItem('chats', JSON.stringify(chats.list));
    if (chats.current) {
      AsyncStorage.setItem('currentChat', chats.current);
    } else {
      AsyncStorage.removeItem('currentChat');
    }
  }, [chats]);

  useEffect(() => {
    AsyncStorage.setItem('tabIndex', tabIndex.toString());
  }, [tabIndex]);

  useEffect(() => {
    AsyncStorage.setItem('subTabIndex', subTabIndex.toString());
  }, [subTabIndex]);

  useEffect(() => {
    AsyncStorage.setItem('sidebarOpen', JSON.stringify(isSidebarOpen));
  }, [isSidebarOpen]);

  const changeCurrent = (id: string) => {
    setChats((prevState) => ({
      ...prevState,
      current: id,
    }));
  };
  
  const clearCurrentChat = () => {
    setChats(prevState => ({
      ...prevState,
      current: null
    }));
  };

  const endChat = async (id: string) => {
    try {
      await whatsAppMultTicketService.updateChatStatus(id, 'closed');
      setChats((prevState) => {
        const newList = prevState.list.map(chat =>
          chat.id === id ? { ...chat, status: 'closed' as 'open' | 'closed' } : chat
        );
        AsyncStorage.setItem('chats', JSON.stringify(newList));
        return {
          ...prevState,
          list: newList,
        };
      });
    } catch (error) {
      console.error('Failed to close chat', error);
    }
  };

// Linha 202 (aproximadamente)
const acceptChat = async (id: string) => {
  try {
    await whatsAppMultTicketService.updateChatClassification(id, 'ASSIGNED', []);
    await updateChatTags(id, 'atendimento_humano');
    setChats((prevState) => {
      const updatedChats = prevState.list.map(chat =>
        chat.id === id ? { 
          ...chat, 
          classification: 'ASSIGNED' as 'ASSIGNED' | 'PENDING'
        } : chat
      );
      AsyncStorage.setItem('chats', JSON.stringify(updatedChats));
      return {
        ...prevState,
        list: updatedChats,
        current: id,
      };
    });
    setTabIndex(0);
    setSubTabIndex(0);
  } catch (error) {
    console.error('Failed to accept chat', error);
    enqueueSnackbar('Falha ao aceitar o chat', { variant: 'error' });
  }
};

  const viewChat = (id: string) => {
    // Implement viewChat logic if needed
  };

  const setPending = async (id: string) => {
    try {
      await whatsAppMultTicketService.updateChatClassification(id, 'PENDING', []);
      await whatsAppMultTicketService.clearChatTags(id);
      setChats((prevState) => {
        const newList = prevState.list.map(chat =>
          chat.id === id
            ? { ...chat, classification: 'PENDING' as 'ASSIGNED' | 'PENDING', tags: [] }
            : chat
        );
        AsyncStorage.setItem('chats', JSON.stringify(newList));
        return {
          ...prevState,
          list: newList,
          current: null,
        };
      });
      enqueueSnackbar('Chat marcado como pendente e tags removidas', { variant: 'success' });
    } catch (error) {
      console.error('Failed to set chat as pending', error);
      enqueueSnackbar('Falha ao marcar chat como pendente', { variant: 'error' });
    }
  };

  const resolveChat = async (id: string) => {
    try {
      await whatsAppMultTicketService.updateChatStatus(id, 'closed');
      await whatsAppMultTicketService.clearChatTags(id);
      setChats((prevState) => {
        const newList = prevState.list.map(chat =>
          chat.id === id ? { ...chat, status: 'closed' as 'open' | 'closed', tags: [] } : chat
        );
        AsyncStorage.setItem('chats', JSON.stringify(newList));
        return {
          ...prevState,
          list: newList,
          current: null,
        };
      });
      enqueueSnackbar('Chat resolvido e tags removidas', { variant: 'success' });
    } catch (error) {
      console.error('Failed to resolve chat', error);
      enqueueSnackbar('Falha ao resolver chat', { variant: 'error' });
    }
  };

  const reopenChat = async (id: string) => {
    try {
      await whatsAppMultTicketService.updateChatStatus(id, 'open');
      setChats((prevState) => {
        const newList = prevState.list.map(chat =>
          chat.id === id ? { ...chat, status: 'open' as 'open' | 'closed' } : chat
        );
        AsyncStorage.setItem('chats', JSON.stringify(newList));
        return {
          ...prevState,
          list: newList,
          current: id,
        };
      });
      setTabIndex(0);
      setSubTabIndex(0);
    } catch (error) {
      console.error('Failed to reopen chat', error);
    }
  };

  const handleSendMessage = async (chatId: string, message: string) => {
    if (message.trim()) {
      try {
        await whatsAppMultTicketService.sendMessage(chatId, message);
      } catch (error) {
        console.error('Failed to send message', error);
      }
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const updateChatTags = async (chatId: string, tag: string) => {
    try {
      await whatsAppMultTicketService.updateChatTags(chatId, tag);
      enqueueSnackbar('Tags atualizadas com sucesso', { variant: 'success' });
    } catch (error) {
      console.error('Failed to update chat tags', error);
      enqueueSnackbar('Falha ao atualizar tags', { variant: 'error' });
    }
  };

  const currentChat = chats.list.find((chat) => chat.id === chats.current) || null;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ position: 'relative', height: 'calc(100vh - 100px)', width: '100%', overflow: 'hidden' }}>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            width: isSidebarOpen ? '350px' : '60px',
            transition: 'width 0.3s ease',
            zIndex: 1,
          }}
        >
          <LeftContainer
            chats={chats}
            changeCurrent={changeCurrent}
            endChat={endChat}
            acceptChat={acceptChat}
            viewChat={viewChat}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            subTabIndex={subTabIndex}
            setSubTabIndex={setSubTabIndex}
            isSidebarOpen={isSidebarOpen}
            toggleSidebar={toggleSidebar}
            updateChatTags={updateChatTags}
          />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: isSidebarOpen ? '350px' : '60px',
            right: 0,
            bottom: 0,
            transition: 'left 0.3s ease',
          }}
        >
          <RightContainer
            chat={currentChat}
            onSetPending={setPending}
            onResolveChat={resolveChat}
            onReopenChat={reopenChat}
            onSendMessage={handleSendMessage}
            tabIndex={tabIndex}
            onClearCurrentChat={clearCurrentChat}
            isSidebarOpen={isSidebarOpen}
          />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default WPComponent;