import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  VStack,
  useColorModeValue,
  Textarea,
  Checkbox,
  HStack,
} from '@chakra-ui/react';
import { FaSave, FaTimes } from 'react-icons/fa';
import { NumericFormat } from 'react-number-format';

interface ProcedimentoModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  procedimento: Partial<{
    id_procedimento?: number;
    procedimento: string;
    ds_procedimento: string;
    tempo_procedimento: string;
    valor_procedimento: string;
    instrucoes_procedimento: string;
  }>;
  setProcedimento: React.Dispatch<React.SetStateAction<Partial<{
    id_procedimento?: number;
    procedimento: string;
    ds_procedimento: string;
    tempo_procedimento: string;
    valor_procedimento: string;
    instrucoes_procedimento: string;
  }>>>;
  isEditing: boolean;
}

const ProcedimentoModal: React.FC<ProcedimentoModalProps> = ({
  isOpen,
  onClose,
  onSave,
  procedimento,
  setProcedimento,
  isEditing,
}) => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const [isGratuito, setIsGratuito] = useState(false);

  useEffect(() => {
    setIsGratuito(procedimento.valor_procedimento === 'GRATUITO');
  }, [procedimento.valor_procedimento]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setProcedimento(prev => ({ ...prev, [name]: value }));
  };

  const handleValorChange = (values: any) => {
    if (!isGratuito) {
      const { floatValue } = values;
      setProcedimento(prev => ({ ...prev, valor_procedimento: floatValue ? floatValue.toString() : '' }));
    }
  };

  const handleGratuitoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsGratuito(e.target.checked);
    if (e.target.checked) {
      setProcedimento(prev => ({ ...prev, valor_procedimento: 'GRATUITO' }));
    } else {
      setProcedimento(prev => ({ ...prev, valor_procedimento: '' }));
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md">
      <ModalOverlay />
      <ModalContent bg={bgColor} borderColor={borderColor} borderWidth={1} borderRadius="md">
        <ModalHeader>{isEditing ? 'Editar Procedimento' : 'Novo Procedimento'}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel>Nome do Procedimento</FormLabel>
              <Input
                name="procedimento"
                value={procedimento.procedimento || ''}
                onChange={handleInputChange}
                placeholder="Nome do procedimento"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Descrição</FormLabel>
              <Input
                name="ds_procedimento"
                value={procedimento.ds_procedimento || ''}
                onChange={handleInputChange}
                placeholder="Descrição do procedimento"
              />
            </FormControl>
            <FormControl>
              <FormLabel>Duração (minutos)</FormLabel>
              <Select
                name="tempo_procedimento"
                value={procedimento.tempo_procedimento || ''}
                onChange={handleInputChange}
              >
                {Array.from({ length: 25 }, (_, i) => (i + 1) * 5).map((min) => (
                  <option key={min} value={min.toString()}>{min} min</option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel>Valor do Procedimento</FormLabel>
              <HStack>
                <NumericFormat
                  customInput={Input}
                  value={isGratuito ? 'GRATUITO' : procedimento.valor_procedimento || ''}
                  onValueChange={handleValorChange}
                  thousandSeparator="."
                  decimalSeparator=","
                  prefix="R$ "
                  decimalScale={2}
                  fixedDecimalScale
                  allowNegative={false}
                  disabled={isGratuito}
                />
                <Checkbox
                  isChecked={isGratuito}
                  onChange={handleGratuitoChange}
                >
                  GRATUITO
                </Checkbox>
              </HStack>
            </FormControl>
            <FormControl>
              <FormLabel>Instruções do Procedimento</FormLabel>
              <Textarea
                name="instrucoes_procedimento"
                value={procedimento.instrucoes_procedimento || ''}
                onChange={handleInputChange}
                placeholder="Digite as instruções detalhadas do procedimento"
                size="sm"
                resize="vertical"
                minHeight="150px"
              />
            </FormControl>
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button leftIcon={<FaSave />} colorScheme="blue" mr={3} onClick={onSave}>
            Salvar
          </Button>
          <Button leftIcon={<FaTimes />} onClick={onClose}>Cancelar</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ProcedimentoModal;