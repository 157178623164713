import React, { useState, useEffect } from 'react';
import {
  Text, Input, Switch, Tooltip, FormControl,
  FormLabel, FormErrorMessage, Card, Button, VStack, HStack, useToast
} from '@chakra-ui/react';
import { fetchProfissionalById, deleteProfissional, Profissional } from 'services/profissionaisService';
import { uploadImage } from 'services/imagesService';
import Config from 'config/Config';
import CustomButton from 'components/CustomButton';
import TagPickerEspecialidades, { Tag as EspecialidadeTag } from 'components/TagPickerEspecialidades';
import TagPickerConvenios, { Tag as ConvenioTag } from 'components/TagPickerConvenios';
import QDAtendimentoService from 'services/qdatendimentoService';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useAuth } from 'context/authContext';
import {
  ProfileContainer,
  ProfileImageLabel,
  ProfileImage,
  FormContainer,
  FormField,
  FormRow,
  ControlButtons,
  StyledHeading,
  InfoContainer,
  HeaderContainer,
} from './ProfissionalProfile.styles';
import { FaPlus, FaMinus } from 'react-icons/fa';

const API_URL = Config.API_URL;

interface ProfissionalProfileProps {
  id_profissional: number | null;
  onCancel: () => void;
  onSave: (profissional: Partial<Profissional>) => Promise<Profissional | undefined>;
  isAdding: boolean;
  especialidades: EspecialidadeTag[];
  convenios: ConvenioTag[];
  isProfissionaisModule?: boolean;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  updateProfissionaisList: () => void;
  especialidadeError: string | null;
  setEspecialidadeError: React.Dispatch<React.SetStateAction<string | null>>;
  convenioError: string | null;
  setConvenioError: React.Dispatch<React.SetStateAction<string | null>>;
  setIsAdding: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedProfissionalId: React.Dispatch<React.SetStateAction<number | null>>;
}

const ProfissionalProfile: React.FC<ProfissionalProfileProps> = ({
  id_profissional,
  onCancel,
  onSave,
  isAdding,
  setIsAdding,
  especialidades,
  convenios,
  isProfissionaisModule,
  isEditing,
  setIsEditing,
  updateProfissionaisList,
  especialidadeError,
  setEspecialidadeError,
  convenioError,
  setConvenioError,
  setSelectedProfissionalId,
}) => {
  const { userData } = useAuth();
  const [profissional, setProfissional] = useState<Partial<Profissional> | null>(null);
  const [originalProfissional, setOriginalProfissional] = useState<Partial<Profissional> | null>(null);
  const [profileImageFile, setProfileImageFile] = useState<File | null>(null);
  const [profileImageUrl, setProfileImageUrl] = useState<string | null>(null);
  const [formStatus, setFormStatus] = useState('Ativo');
  const [formEspecialidades, setFormEspecialidades] = useState<EspecialidadeTag[]>([]);
  const [formConvenios, setFormConvenios] = useState<ConvenioTag[]>([]);
  const [ausenciaStart, setAusenciaStart] = useState<Date | undefined>(undefined);
  const [ausenciaEnd, setAusenciaEnd] = useState<Date | undefined>(undefined);
  const [ausencias, setAusencias] = useState<Array<{ inicio: Date | null; fim: Date | null }>>([]);
  const [ausenciaError, setAusenciaError] = useState<string | null>(null);
  const toast = useToast();

  useEffect(() => {
    if (!isAdding && id_profissional !== null) {
      const loadProfissional = async () => {
        try {
          const profissionalData = await fetchProfissionalById(id_profissional.toString());
          setProfissional(profissionalData);
          setOriginalProfissional(profissionalData);
          setProfileImageUrl(profissionalData.url_imagem ? `${API_URL}${profissionalData.url_imagem}` : null);
          setFormStatus(profissionalData.status_profissional || 'Ativo');
          setFormEspecialidades(
            (profissionalData.especialidades || []).map((especialidade, index) => ({
              id: index,
              label: especialidade,
              color: especialidades.find(tag => tag.label === especialidade)?.color || '',
            }))
          );

          setFormConvenios(
            (profissionalData.convenios || []).map((convenio, index) => ({
              id: index,
              label: convenio,
              color: convenios.find(tag => tag.label === convenio)?.color || '',
              convenioId: convenios.find(tag => tag.label === convenio)?.convenioId || 0,
              id_profissional: profissionalData.id_profissional || 0,
            }))
          );
          setAusenciaStart(profissionalData.ausencia_start ? new Date(profissionalData.ausencia_start) : undefined);
          setAusenciaEnd(profissionalData.ausencia_end ? new Date(profissionalData.ausencia_end) : undefined);

          // Inicializa as ausências
          setAusencias(profissionalData.ausencias || []);

        } catch (error) {
          console.error('Failed to fetch profissional', error);
          alert('Failed to fetch profissional. See console for details.');
        }
      };

      loadProfissional();
    } else {
      setProfissional({
        nome_profissional: '',
        email_profissional: '',
        url_imagem: '',
        status_profissional: 'Ativo',
        especialidades: [],
        fone_profissional: '',
        clinica: userData?.id_clinica || '',
        id_agenda: 0,
        ausencia_start: undefined,
        ausencia_end: undefined,
        ausencias: [],
      });
      setIsEditing(true);
    }
  }, [id_profissional, isAdding, userData, especialidades, convenios, setIsEditing]);

  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault();
    if (profissional) {
      if (formEspecialidades.length === 0) {
        setEspecialidadeError("Selecione pelo menos uma especialidade.");
        return;
      }

      if (formConvenios.length === 0) {
        setConvenioError("Selecione pelo menos um convênio.");
        return;
      }

      if (ausenciaStart && ausenciaEnd && ausenciaStart > ausenciaEnd) {
        setAusenciaError('A data de início da ausência deve ser anterior ou igual à data de término.');
        return;
      }

      // Validação das ausências
      for (const ausencia of ausencias) {
        if (ausencia.inicio && ausencia.fim && ausencia.inicio > ausencia.fim) {
          setAusenciaError('Todas as ausências devem ter a data de início anterior ou igual à data de fim.');
          return;
        }
      }

      profissional.ausencia_start = ausenciaStart || undefined;
      profissional.ausencia_end = ausenciaEnd || undefined;

      // Remove formatação do valor_procedimento antes de salvar
      const valorProcedimentoSemFormatacao = profissional.valor_procedimento?.replace(/\D/g, '');
      profissional.valor_procedimento = valorProcedimentoSemFormatacao;

      if (!isAdding) {
        const conveniosWithoutSchedule = await Promise.all(
          formConvenios.map(async (convenio) => {
            const schedule = await QDAtendimentoService.findByConvenioAndProfissional(
              convenio.convenioId,
              profissional.id_profissional!
            );
            return schedule.length === 0 ? convenio : null;
          })
        );

        const missingSchedules = conveniosWithoutSchedule.filter(Boolean);
        if (missingSchedules.length > 0) {
          const errorMessage = `Os seguintes convênios não têm quadro de horários: ${missingSchedules.map(c => c!.label).join(', ')}`;
          setConvenioError(errorMessage);
          return;
        }
      }

      profissional.status_profissional = formStatus;
      profissional.especialidades = formEspecialidades.map(tag => tag.label);
      profissional.convenios = formConvenios.map(tag => tag.label);

      if (profileImageFile) {
        const uploadedImage = await uploadImage(profileImageFile);
        profissional.url_imagem = uploadedImage.imageUrl;
      }

      // Atualiza as ausências
      profissional.ausencias = ausencias.filter(a => a.inicio && a.fim);

      const savedProfissional = await onSave(profissional);

      if (savedProfissional && isAdding) {
        setIsAdding(false);
        setIsEditing(true);
        setProfissional(savedProfissional);
        setSelectedProfissionalId(savedProfissional.id_profissional || null);
        await handleSave(e);
      } else {
        setIsEditing(false);
        setProfileImageFile(null);
        setProfileImageUrl(null);
      }
    }
  };

  const handleCancel = () => {
    setIsEditing(false);
    if (!isAdding && originalProfissional) {
      setProfissional(originalProfissional);
      setProfileImageUrl(originalProfissional.url_imagem ? `${API_URL}${originalProfissional.url_imagem}` : null);
      setFormStatus(originalProfissional.status_profissional || 'Ativo');
      setFormEspecialidades(
        (originalProfissional.especialidades || []).map((especialidade, index) => ({
          id: index,
          label: especialidade,
          color: especialidades.find(tag => tag.label === especialidade)?.color || '',
        }))
      );
      setFormConvenios(
        (originalProfissional.convenios || []).map((convenio, index) => ({
          id: index,
          label: convenio,
          color: convenios.find(tag => tag.label === convenio)?.color || '',
          convenioId: convenios.find(tag => tag.label === convenio)?.convenioId || 0,
          id_profissional: originalProfissional.id_profissional || 0,
        }))
      );
    } else {
      onCancel();
    }
    setProfileImageFile(null);
    setEspecialidadeError(null);
    setConvenioError(null);
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setProfileImageFile(file);
      setProfileImageUrl(URL.createObjectURL(file));
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (profissional) {
      const { name, value } = e.target;
      setProfissional({ ...profissional, [name]: value });
      if (name === 'status_profissional') {
        setFormStatus(value);
      }
    }
  };

  const formatPhoneNumber = (value: string) => {
    const cleaned = value.replace(/\D/g, '');
    let formatted = cleaned;
    if (cleaned.length > 2) {
      formatted = `(${cleaned.slice(0, 2)})${cleaned.slice(2)}`;
    }
    if (cleaned.length > 7) {
      formatted = `${formatted.slice(0, 9)}-${formatted.slice(9, 13)}`;
    }
    return formatted;
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedValue = formatPhoneNumber(e.target.value);
    if (profissional) {
      setProfissional({ ...profissional, fone_profissional: formattedValue });
    }
  };

  const handleTagSelect = (tag: EspecialidadeTag) => {
    setFormEspecialidades([...formEspecialidades, tag]);
    setEspecialidadeError(null);
  };

  const handleTagDeselect = (tag: EspecialidadeTag) => {
    setFormEspecialidades(formEspecialidades.filter(t => t.id !== tag.id));
  };

  const handleConvenioSelect = (tag: ConvenioTag) => {
    setFormConvenios([...formConvenios, tag]);
    setConvenioError(null);
  };

  const handleConvenioDeselect = (tag: ConvenioTag) => {
    setFormConvenios(formConvenios.filter(t => t.id !== tag.id));
  };

  const handleDeleteProfissional = async () => {
    const confirmed = window.confirm('Você tem certeza que deseja deletar este profissional?');
    if (confirmed && profissional && profissional.id_profissional !== undefined) {
      try {
        await deleteProfissional(profissional.id_profissional.toString());
        alert('Profissional deletado com sucesso');
        updateProfissionaisList();
        onCancel();
      } catch (error) {
        console.error('Failed to delete profissional', error);
        alert('Failed to delete profissional. See console for details.');
      }
    }
  };

  const handleAddAusencia = () => {
    if (isEditing) {
      setAusencias([...ausencias, { inicio: null, fim: null }]);
    }
  };

  const handleRemoveAusencia = (index: number) => {
    if (isEditing) {
      const newAusencias = ausencias.filter((_, i) => i !== index);
      setAusencias(newAusencias);
    }
  };

  const handleAusenciaChange = (index: number, field: 'inicio' | 'fim', value: Date | null) => {
    const newAusencias = ausencias.map((ausencia, i) => 
      i === index ? { ...ausencia, [field]: value } : ausencia
    );

    // Validação: início <= fim
    const currentAusencia = newAusencias[index];
    if (currentAusencia) {
      if (field === 'inicio' && value && currentAusencia.fim) {
        if (value > currentAusencia.fim) {
          toast({
            title: "Erro na data",
            description: "A data de início deve ser anterior ou igual à data de fim.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          return;
        }
      }
      if (field === 'fim' && value && currentAusencia.inicio) {
        if (value < currentAusencia.inicio) {
          toast({
            title: "Erro na data",
            description: "A data de fim deve ser posterior ou igual à data de início.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
          return;
        }
      }
    }

    setAusencias(newAusencias);
    setAusenciaError(null); // Limpa o erro se a validação passar
  };

  return (
    <ProfileContainer>
      <HeaderContainer>
        <StyledHeading>Perfil de Profissional</StyledHeading>
        <ControlButtons>
          {!isEditing && !isAdding && (
            <>
              <CustomButton
                text="Editar"
                colorScheme="blue"
                onClick={() => setIsEditing(true)}
              />
              <CustomButton
                text="Deletar"
                colorScheme="red"
                onClick={handleDeleteProfissional}
              />
            </>
          )}
        </ControlButtons>
      </HeaderContainer>
      {profissional && (
        <FormContainer onSubmit={handleSave}>
          <FormRow>
            <Tooltip label="Clique no botão de Editar e depois aqui para alterar a imagem do perfil.">
              <ProfileImageLabel htmlFor="profileImageUpload">
                <ProfileImage
                  src={profileImageUrl || '/perfil_default.jpeg'}
                  alt="Imagem de Perfil"
                />
              </ProfileImageLabel>
            </Tooltip>
            <Input
              type="file"
              id="profileImageUpload"
              name="profile_image"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: 'none' }}
              disabled={!isEditing}
            />
            <InfoContainer>
              <Text fontSize="lg">{profissional.nome_profissional}</Text>
              <Text fontSize="sm">{profissional.email_profissional}</Text>
              <FormRow>
                <FormField>
                  <Switch
                    isChecked={formStatus === 'Ativo'}
                    onChange={(e) => {
                      const status = e.target.checked ? 'Ativo' : 'Inativo';
                      setFormStatus(status);
                      if (profissional) {
                        setProfissional({ ...profissional, status_profissional: status });
                      }
                    }}
                    disabled={!isEditing}
                    colorScheme="green"
                  />
                </FormField>
              </FormRow>
            </InfoContainer>
          </FormRow>
          <Card style={{ marginBottom: 5, marginTop: 5, padding: 5 }}>
                        <FormField>
              <label>Nome</label>
              <Input
                type="text"
                name="nome_profissional"
                value={profissional.nome_profissional || ''}
                onChange={handleChange}
                required
                disabled={!isEditing}
              />
            </FormField>
            <FormRow>
              <FormField>
                <label>Email</label>
                <Input
                  type="email"
                  name="email_profissional"
                  value={profissional.email_profissional || ''}
                  onChange={handleChange}
                  required
                  disabled={!isEditing}
                />
              </FormField>
              <FormField>
                <label>Telefone</label>
                <Input
                  type="tel"
                  name="fone_profissional"
                  value={profissional.fone_profissional || ''}
                  onChange={handlePhoneChange}
                  disabled={!isEditing}
                  maxLength={14}
                  placeholder="(XX)9XXXX-XXXX"
                />
              </FormField>
            </FormRow>
          </Card>
          <Card style={{ marginBottom: 5, marginTop: 5, padding: 5 }}>
            <FormControl isInvalid={!!especialidadeError}>
              <FormLabel>Especialidades</FormLabel>
              <TagPickerEspecialidades
                selectedTags={formEspecialidades}
                onTagSelect={handleTagSelect}
                onTagDeselect={handleTagDeselect}
                isEditable={isEditing}
              />
              {especialidadeError && (
                <FormErrorMessage>{especialidadeError}</FormErrorMessage>
              )}
            </FormControl>
            
            <FormControl isInvalid={!!convenioError}>
              <FormLabel>Convênios</FormLabel>
              <TagPickerConvenios
                selectedTags={formConvenios}
                onTagSelect={handleConvenioSelect}
                onTagDeselect={handleConvenioDeselect}
                isEditable={isEditing}
                clinicaId={userData?.id_clinica || ''}
                id_profissional={profissional.id_profissional || 0}
                isProfissionaisModule={isProfissionaisModule}
              />
              {convenioError && (
                <FormErrorMessage>{convenioError}</FormErrorMessage>
              )}
            </FormControl>
          </Card>

          <Card style={{ marginBottom: 5, marginTop: 5, padding: 5 }}>
            <FormControl>
              <FormLabel>Ausências Programadas</FormLabel>
              <VStack align="stretch" spacing={4}>
                {ausencias.map((ausencia, index) => (
                  <HStack key={index} spacing={4}>
                    <FormControl>
                      <FormLabel>Início</FormLabel>
                      <DatePicker
                        selected={ausencia.inicio}
                        onChange={(date) => handleAusenciaChange(index, 'inicio', date)}
                        dateFormat="dd/MM/yyyy"
                        isClearable
                        placeholderText="Selecione a data de início"
                        disabled={!isEditing}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel>Fim</FormLabel>
                      <DatePicker
                        selected={ausencia.fim}
                        onChange={(date) => handleAusenciaChange(index, 'fim', date)}
                        dateFormat="dd/MM/yyyy"
                        isClearable
                        placeholderText="Selecione a data de fim"
                        disabled={!isEditing}
                      />
                    </FormControl>
                    {isEditing && (
                      <Button
                        onClick={() => handleRemoveAusencia(index)}
                        colorScheme="red"
                      >
                        <FaMinus />
                      </Button>
                    )}
                  </HStack>
                ))}
                {isEditing && (
                  <Button
                    onClick={handleAddAusencia}
                    colorScheme="green"
                    leftIcon={<FaPlus />}
                  >
                    Adicionar Ausência
                  </Button>
                )}
              </VStack>
              {ausenciaError && (
                <FormErrorMessage>{ausenciaError}</FormErrorMessage>
              )}
            </FormControl>
          </Card>
          {isEditing || isAdding ? (
            <ControlButtons>
              <CustomButton text="Salvar" colorScheme="blue" type="submit" />
              <CustomButton
                text="Cancelar"
                colorScheme="gray"
                onClick={handleCancel}
                ml={2}
              />
            </ControlButtons>
          ) : null}
        </FormContainer>
      )}
    </ProfileContainer>
  );
};

export default ProfissionalProfile;