import React, { useState, useEffect, useCallback } from 'react';
import {
  ProfissionaisContainer,
  ContentWrapper,
  SearchInput,
  SidebarContainer,
  RightContainer,
  Flag,
  FiltersWrapper,
  Footer,
  RadioWrapper,
  RadioLabel,
  ProfileImage,
  ProfissionalListContainer,
  ProfissionalCard,
  ProfissionalInfo,
  ProfissionalName,
  ProfissionalRole,
  StyledInputLeftElement,
  SidebarToggleButton,
  AddProfissionalIconButton,
  ProcedimentoCard,
  TabHeader,
  SidebarHeader,
  SidebarCloseButton,
} from './ProfissionaisModule.styles';
import {
  fetchProfissionais,
  createProfissional,
  updateProfissional,
  fetchProfissionalById,
  Profissional
} from 'services/profissionaisService';
import { fetchEspecialidades, Especialidade } from 'services/especialidadesService';
import { fetchConveniosByClinica, Convenio } from 'services/conveniosService';
import { fetchProcedimentosByProfissional, createProcedimento, updateProcedimento, deleteProcedimento, Procedimento } from 'services/procedimentosService';
import { FaCircle, FaCheckCircle, FaTimesCircle, FaSearch, FaChevronLeft, FaChevronRight, FaUserMd, FaPlus, FaEdit, FaTrash, FaInfoCircle } from 'react-icons/fa';
import Config from 'config/Config';
import ProfissionalProfile from 'components/ProfissionalProfile';
import ProcedimentoModal from './ProcedimentoModal';
import { useAuth } from 'context/authContext';
import { useToast, Tooltip, HStack, Text, Tabs, TabList, TabPanels, Tab, TabPanel, Button, VStack, SimpleGrid, Box } from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { Tag as EspecialidadeTag } from 'components/TagPickerEspecialidades';
import { Tag as ConvenioTag } from 'components/TagPickerConvenios';

const API_URL = Config.API_URL;

const ProfissionaisModule: React.FC = () => {
  const { userData } = useAuth();
  const [profissionais, setProfissionais] = useState<Profissional[]>([]);
  const [selectedProfissionalId, setSelectedProfissionalId] = useState<number | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('Ativo');
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [tags, setTags] = useState<EspecialidadeTag[]>([]);
  const [convenioTags, setConvenioTags] = useState<ConvenioTag[]>([]);
  const [especialidadeError, setEspecialidadeError] = useState<string | null>(null);
  const [convenioError, setConvenioError] = useState<string | null>(null);
  const [procedimentos, setProcedimentos] = useState<Procedimento[]>([]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentProcedimento, setCurrentProcedimento] = useState<Partial<Procedimento>>({});
  const [isEditingProcedimento, setIsEditingProcedimento] = useState(false);
  const toast = useToast();

  const loadProfissionais = useCallback(async () => {
    try {
      if (!userData?.id_clinica) {
        throw new Error('ID da clínica não encontrado.');
      }
      const data = await fetchProfissionais(userData.id_clinica);
      data.sort((a: Profissional, b: Profissional) => a.nome_profissional.localeCompare(b.nome_profissional));
      setProfissionais(data);
      setSelectedProfissionalId(data[0]?.id_profissional || null);
    } catch (error) {
      console.error('Failed to load profissionais', error);
      toast({
        title: "Erro",
        description: "Falha ao carregar profissionais. Veja detalhes no console.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [userData, toast]);

  useEffect(() => {
    if (userData) {
      loadProfissionais();
    }
  }, [userData, loadProfissionais]);

  useEffect(() => {
    const loadTags = async () => {
      try {
        const especialidades = await fetchEspecialidades();
        setTags(especialidades.map((especialidade: Especialidade) => ({
          id: especialidade.id,
          label: especialidade.ds_especialidades,
          color: especialidade.cor,
        })));
      } catch (error) {
        console.error('Failed to load tags', error);
      }
    };
    const loadConvenioTags = async () => {
      if (!userData?.id_clinica) {
        return;
      }
      try {
        const convenios = await fetchConveniosByClinica(userData.id_clinica.toString());
        setConvenioTags(convenios.map((convenio: Convenio) => ({
          id: convenio.id_convenio,
          label: convenio.ds_convenio,
          color: convenio.cor,
          convenioId: convenio.id_convenio,
          id_profissional: 0,
        })));
      } catch (error) {
        console.error('Failed to load convenio tags', error);
      }
    };
    if (userData?.id_clinica) {
      loadTags();
      loadConvenioTags();
    }
  }, [userData]);

  useEffect(() => {
    const loadProcedimentos = async () => {
      if (selectedProfissionalId !== null) {
        try {
          const data = await fetchProcedimentosByProfissional(selectedProfissionalId);
          setProcedimentos(data);
        } catch (error) {
          console.error('Failed to load procedimentos', error);
        }
      }
    };
    loadProcedimentos();
  }, [selectedProfissionalId]);

  const handleAddProfissional = () => {
    if (!userData?.id_clinica) {
      toast({
        title: "Erro",
        description: "ID da clínica não encontrado.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    setSelectedProfissionalId(null);
    setIsEditing(true);
    setIsAdding(true);
    setProcedimentos([{
      id_procedimento: 0,
      procedimento: "Consulta",
      ds_procedimento: "Consulta Padrão",
      tempo_procedimento: "30",
      valor_procedimento: '',
      profissionalId: 0
    }]);
  };

  const handleSaveProfissional = async (profissional: Partial<Profissional>): Promise<Profissional | undefined> => {
    if (!profissional.especialidades || profissional.especialidades.length === 0) {
      setEspecialidadeError("Selecione pelo menos uma especialidade.");
      return undefined;
    }
    if (!profissional.convenios || profissional.convenios.length === 0) {
      setConvenioError("Selecione pelo menos um convênio.");
      return undefined;
    }
    setEspecialidadeError(null);
    setConvenioError(null);
    try {
      if (isAdding) {
        const newProfissional = await createProfissional({ ...profissional, clinica: userData?.id_clinica });
        setProfissionais([...profissionais, newProfissional]);
        // Create default "Consulta" procedure
        await createProcedimento({
          procedimento: "Consulta",
          ds_procedimento: "Consulta Padrão",
          tempo_procedimento: "30",
          profissionalId: newProfissional.id_profissional
        });
      } else if (selectedProfissionalId) {
        const updatedProfissional = await updateProfissional(selectedProfissionalId.toString(), profissional as Profissional);
        setProfissionais(profissionais.map((p) => p.id_profissional === updatedProfissional.id_profissional ? updatedProfissional : p));
      }
      setIsEditing(false);
      setIsAdding(false);
      setSelectedProfissionalId(profissionais[0]?.id_profissional || null);
      loadProfissionais();
    } catch (error) {
      console.error('Failed to save profissional', error);
      toast({
        title: "Erro",
        description: "Falha ao salvar profissional. Veja detalhes no console.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleCancelEdit = async () => {
    setIsEditing(false);
    setIsAdding(false);
    if (selectedProfissionalId) {
      try {
        const profissionalData = await fetchProfissionalById(selectedProfissionalId.toString());
        setProfissionais((prevProfissionais) =>
          prevProfissionais.map((p) => (p.id_profissional === profissionalData.id_profissional ? profissionalData : p))
        );
        setSelectedProfissionalId(profissionalData.id_profissional);
      } catch (error) {
        console.error('Failed to refresh profissional', error);
      }
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleAddProcedimento = () => {
    setCurrentProcedimento({ profissionalId: selectedProfissionalId ?? 0 });
    setIsEditingProcedimento(false);
    setIsModalOpen(true);
  };

  const handleEditProcedimento = (procedimento: Procedimento) => {
    setCurrentProcedimento(procedimento);
    setIsEditingProcedimento(true);
    setIsModalOpen(true);
  };

  const handleSaveProcedimento = async () => {
    if (!currentProcedimento.procedimento || !currentProcedimento.tempo_procedimento) {
      toast({
        title: "Erro",
        description: "Procedimento e Duração são obrigatórios.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      if (isEditingProcedimento) {
        const updatedProcedimento = await updateProcedimento(currentProcedimento.id_procedimento!, currentProcedimento as Procedimento);
        setProcedimentos(procedimentos.map((p) => p.id_procedimento === updatedProcedimento.id_procedimento ? updatedProcedimento : p));
      } else {
        const savedProcedimento = await createProcedimento({
          ...currentProcedimento,
          profissionalId: selectedProfissionalId!,
        } as Procedimento);
        setProcedimentos([...procedimentos, savedProcedimento]);
      }
      setIsModalOpen(false);
      setCurrentProcedimento({});
    } catch (error) {
      console.error('Failed to save procedimento', error);
      toast({
        title: "Erro",
        description: "Falha ao salvar o procedimento.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteProcedimento = async (id: number) => {
    if (procedimentos.length <= 1) {
      toast({
        title: "Aviso",
        description: "Não é permitido deletar todos os procedimentos de um profissional.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    const confirmed = window.confirm('Tem certeza que deseja deletar este procedimento?');
    if (!confirmed) return;
    try {
      await deleteProcedimento(id);
      setProcedimentos(procedimentos.filter((p) => p.id_procedimento !== id));
    } catch (error) {
      console.error('Failed to delete procedimento', error);
      toast({
        title: "Erro",
        description: "Falha ao deletar o procedimento.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const filteredProfissionais = profissionais
    .filter((profissional) => profissional.nome_profissional.toLowerCase().includes(searchTerm.toLowerCase()))
    .filter((profissional) => filterStatus === 'Todos' ? true : profissional.status_profissional === filterStatus);

  return (
    <ProfissionaisContainer>
      <ContentWrapper>
        <SidebarContainer isOpen={isSidebarOpen}>
          <SidebarHeader>
            <SidebarCloseButton onClick={toggleSidebar}>
              <FaTimesCircle />
            </SidebarCloseButton>
          </SidebarHeader>
          <FiltersWrapper>
            <SearchInput>
              <StyledInputLeftElement>
                <FaSearch />
              </StyledInputLeftElement>
              <input
                type="text"
                placeholder="Pesquisar por nome"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </SearchInput>
            <RadioWrapper>
              <Tooltip label="Todos">
                <RadioLabel>
                  <input
                    type="radio"
                    name="status"
                    value="Todos"
                    checked={filterStatus === 'Todos'}
                    onChange={(e) => setFilterStatus(e.target.value)}
                  />
                  <FaCircle />
                </RadioLabel>
              </Tooltip>
              <Tooltip label="Ativo">
                <RadioLabel>
                  <input
                    type="radio"
                    name="status"
                    value="Ativo"
                    checked={filterStatus === 'Ativo'}
                    onChange={(e) => setFilterStatus(e.target.value)}
                  />
                  <FaCheckCircle />
                </RadioLabel>
              </Tooltip>
              <Tooltip label="Inativo">
                <RadioLabel>
                  <input
                    type="radio"
                    name="status"
                    value="Inativo"
                    checked={filterStatus === 'Inativo'}
                    onChange={(e) => setFilterStatus(e.target.value)}
                    />
                    <FaTimesCircle />
                  </RadioLabel>
                </Tooltip>
              </RadioWrapper>
              <Tooltip label="Adicionar profissional" placement="right">
                <AddProfissionalIconButton onClick={handleAddProfissional}>
                  <FaUserMd />
                </AddProfissionalIconButton>
              </Tooltip>
            </FiltersWrapper>
            <ProfissionalListContainer>
              <AnimatePresence>
                {filteredProfissionais.map((profissional, index) => (
                  <motion.div
                    key={profissional.id_profissional}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.2, delay: index * 0.05 }}
                  >
                    <ProfissionalCard
                      onClick={() => setSelectedProfissionalId(profissional.id_profissional)}
                      isSelected={selectedProfissionalId === profissional.id_profissional}
                    >
                      <HStack spacing={4}>
                        <ProfileImage
                          src={profissional.url_imagem ? `${API_URL}${profissional.url_imagem}` : '/perfil_default.jpeg'}
                          alt="Imagem de Perfil"
                        />
                        <ProfissionalInfo>
                          <ProfissionalName>{profissional.nome_profissional}</ProfissionalName>
                          <ProfissionalRole>{profissional.especialidades[0] || 'Sem especialidade'}</ProfissionalRole>
                        </ProfissionalInfo>
                      </HStack>
                      <Flag $status={profissional.status_profissional.toLowerCase()} />
                    </ProfissionalCard>
                  </motion.div>
                ))}
              </AnimatePresence>
            </ProfissionalListContainer>
            <Footer>
              <Text fontSize="sm" color="gray.500">Total de profissionais: {filteredProfissionais.length}</Text>
            </Footer>
          </SidebarContainer>
          <RightContainer>
            <TabHeader>
              <SidebarToggleButton onClick={toggleSidebar}>
                {isSidebarOpen ? <FaChevronLeft /> : <FaChevronRight />}
              </SidebarToggleButton>
            </TabHeader>
            <Tabs index={activeTabIndex} onChange={setActiveTabIndex}>
              <TabList>
                <Tab>Perfil</Tab>
                <Tab>Procedimentos</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Box maxWidth="100%" overflowX="hidden">
                    {selectedProfissionalId && !isAdding ? (
                      <ProfissionalProfile
                        id_profissional={selectedProfissionalId}
                        onCancel={handleCancelEdit}
                        onSave={handleSaveProfissional}
                        isAdding={false}
                        especialidades={tags}
                        convenios={convenioTags}
                        isProfissionaisModule={true}
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                        updateProfissionaisList={loadProfissionais}
                        especialidadeError={especialidadeError}
                        setEspecialidadeError={setEspecialidadeError}
                        convenioError={convenioError}
                        setConvenioError={setConvenioError}
                        setIsAdding={setIsAdding}
                        setSelectedProfissionalId={setSelectedProfissionalId}
                      />
                    ) : isAdding ? (
                      <ProfissionalProfile
                        id_profissional={null}
                        onCancel={handleCancelEdit}
                        onSave={handleSaveProfissional}
                        isAdding={true}
                        especialidades={tags}
                        convenios={convenioTags}
                        isProfissionaisModule={true}
                        isEditing={true}
                        setIsEditing={setIsEditing}
                        updateProfissionaisList={loadProfissionais}
                        especialidadeError={especialidadeError}
                        setEspecialidadeError={setEspecialidadeError}
                        convenioError={convenioError}
                        setConvenioError={setConvenioError}
                        setIsAdding={setIsAdding}
                        setSelectedProfissionalId={setSelectedProfissionalId}
                      />
                    ) : null}
                  </Box>
                </TabPanel>
                <TabPanel>
                  <VStack spacing={4} align="stretch">
                    <Button
                      leftIcon={<FaPlus />}
                      colorScheme="blue"
                      onClick={handleAddProcedimento}
                    >
                      Novo Procedimento
                    </Button>
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 4 }} spacing={4} w="100%">
                      {procedimentos.map((procedimento) => (
                        <ProcedimentoCard key={procedimento.id_procedimento}>
                          <HStack justifyContent="space-between" w="100%">
                            <VStack align="start" spacing={1}>
                              <HStack>
                                <Text fontWeight="bold">{procedimento.procedimento}</Text>
                                {procedimento.instrucoes_procedimento && (
                                  <Tooltip label={procedimento.instrucoes_procedimento} placement="top">
                                    <Box as="span">
                                      <FaInfoCircle color="blue.500" />
                                    </Box>
                                  </Tooltip>
                                )}
                              </HStack>
                              <Text fontSize="sm" color="gray.600">{procedimento.ds_procedimento}</Text>
                              <Text fontSize="sm" color="blue.500">Duração: {procedimento.tempo_procedimento} min</Text>
                              <Text fontWeight="bold">Valor: {procedimento.valor_procedimento}</Text>
                              <HStack>
                                <Button
                                  leftIcon={<FaEdit />}
                                  colorScheme="yellow"
                                  onClick={() => handleEditProcedimento(procedimento)}
                                  size="sm"
                                >
                                  Editar
                                </Button>
                                <Button
                                  leftIcon={<FaTrash />}
                                  colorScheme="red"
                                  onClick={() => handleDeleteProcedimento(procedimento.id_procedimento)}
                                  size="sm"
                                >
                                  Deletar
                                </Button>
                              </HStack>
                            </VStack>
                          </HStack>
                        </ProcedimentoCard>
                      ))}
                    </SimpleGrid>
                  </VStack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </RightContainer>
        </ContentWrapper>
        <ProcedimentoModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSave={handleSaveProcedimento}
          procedimento={currentProcedimento}
          setProcedimento={setCurrentProcedimento}
          isEditing={isEditingProcedimento}
        />
      </ProfissionaisContainer>
    );
  };
  
  export default ProfissionaisModule;