import styled, { css } from 'styled-components';
import { ChromePicker } from 'react-color';
import { Input, Select } from '@chakra-ui/react';

const glassmorphismStyle = css`
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
`;

export const AnotacoesContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 40px;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
`;

export const AnotacoesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const AnotacoesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  overflow-y: auto;
  padding: 10px;
`;

export const NovaAnotacaoCard = styled.div<{ isCreating: boolean; cor: string }>`
  ${glassmorphismStyle}
  background-color: ${props => props.cor};
  border-radius: 12px;
  padding: 20px;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.isCreating ? 'flex-start' : 'center'};
  align-items: ${props => props.isCreating ? 'stretch' : 'center'};
  white-space: pre-wrap;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
  width: 80%; // Adicionado para reduzir a largura para 80%
  margin: 0 auto; // Centraliza o card

  &:hover {
    transform: translateY(-5px);
  }
`;

export const AnotacaoCard = styled.div<{ cor: string }>`
  ${glassmorphismStyle}
  background-color: ${props => props.cor};
  border-radius: 12px;
  padding: 20px;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  white-space: pre-wrap;
  transition: all 0.3s ease;
  position: relative;
  overflow: visible; // Adicionado para permitir que o ícone fique fora do card
  width: 80%; // Adicionado para reduzir a largura para 80%
  margin: 0 auto; // Centraliza o card

  &:hover {
    transform: translateY(-5px);
  }
`;

export const DeleteButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #4a5568;
  font-size: 18px;
  cursor: pointer;
  opacity: 0.6;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 1;
  }
`;

export const StyledColorPicker = styled(ChromePicker)`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  ${glassmorphismStyle}
  border-radius: 8px;
`;

export const ColorPickerOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

export const ColorPickerButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  color: #4a5568;
  margin-right: 10px;
  transition: all 0.2s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

export const FooterControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  gap: 10px;
`;

export const SaveButton = styled.button`
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  color: white;
  padding: 8px 16px;
  border-radius: 8px;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  }
`;

export const FloatingLabel = styled.div`
  position: relative;
  margin-bottom: 20px;

  span {
    position: absolute;
    top: -10px;
    left: 10px;
    background: white;
    padding: 0 5px;
    font-size: 12px;
    color: #4a5568;
    transition: all 0.2s ease;
  }
`;

export const GlassmorphicInput = styled(Input)`
  ${glassmorphismStyle}
  background: rgba(255, 255, 255, 0.1);
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  color: #2d3748;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.6);
  }

  &::placeholder {
    color: #a0aec0;
  }
`;

export const GlassmorphicSelect = styled(Select)`
  ${glassmorphismStyle}
  background: rgba(255, 255, 255, 0.1);
  border: none;
  padding: 8px 12px;
  font-size: 14px;
  color: #2d3748;
  transition: all 0.3s ease;
  min-width: 120px;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.6);
  }

  option {
    background-color: white;
    color: #2d3748;
  }
`;

export const GlassmorphicDatePicker = styled.input`
  ${glassmorphismStyle}
  background: rgba(255, 255, 255, 0.1);
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  color: #2d3748;
  transition: all 0.3s ease;
  border-radius: 8px;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.6);
  }

  &::-webkit-calendar-picker-indicator {
    filter: invert(0.5);
    cursor: pointer;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
`;

export const AnotacaoFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  font-size: 14px;
  color: #4a5568;
`;

export const AnotacaoData = styled.span`
  font-style: italic;
`;

export const AnotacaoRelevancia = styled.span`
  font-weight: bold;
  text-transform: uppercase;
`;

// Add responsive styles
const media = {
  desktop: '@media(min-width: 1024px)',
  tablet: '@media(min-width: 768px) and (max-width: 1023px)',
  mobile: '@media(max-width: 767px)',
};

export const ResponsiveAnotacoesGrid = styled(AnotacoesGrid)`
  ${media.desktop} {
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    padding: 20px 60px;
  }

  ${media.tablet} {
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    padding: 20px 40px;
  }

  ${media.mobile} {
    grid-template-columns: 1fr;
    gap: 25px;
    padding: 20px;
  }
`;

// Add dark mode styles
export const DarkModeContainer = styled(AnotacoesContainer)`
  background: linear-gradient(135deg, #2d3748 0%, #1a202c 100%);
  color: #e2e8f0;
`;

export const DarkModeCard = styled(AnotacaoCard)`
  background-color: rgba(45, 55, 72, 0.7);
  color: #e2e8f0;
`;

export const DarkModeInput = styled(GlassmorphicInput)`
  background: rgba(45, 55, 72, 0.3);
  color: #e2e8f0;

  &::placeholder {
    color: #a0aec0;
  }
`;

export const DarkModeSelect = styled(GlassmorphicSelect)`
  background: rgba(45, 55, 72, 0.3);
  color: #e2e8f0;
`;

export const DarkModeDatePicker = styled(GlassmorphicDatePicker)`
  background: rgba(45, 55, 72, 0.3);
  color: #e2e8f0;

  &::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
`;

// Add these new styled components
export const AnotacaoTitulo = styled.h3`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  width: 100%;
  font-family: 'Inter', sans-serif;
`;

export const AnotacaoTexto = styled.textarea`
  font-size: 16px;
  flex-grow: 1;
  border: none;
  background: transparent;
  resize: none;
  width: 100%;
  min-height: 120px;
  margin-bottom: 15px;
  font-family: 'Inter', sans-serif;
`;

export const StyleButton = styled.button<{ active: boolean }>`
  background-color: ${props => props.active ? 'rgba(0, 0, 0, 0.1)' : 'transparent'};
  border: none;
  padding: 8px;
  margin-right: 8px;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.2s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export const StyleButtonsContainer = styled.div`
  display: flex;
  margin-bottom: 15px;
`;

// The rest of the file remains unchanged

export const RelevanciaOptionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const FilterIconsContainer = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  display: flex;
  gap: 12px;
  z-index: 100;
`;

export const FilterIcon = styled.button`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  ${glassmorphismStyle}
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  border: none;
  color: #4a5568;

  &:hover {
    transform: translateY(-2px);
    
    .tooltip {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }
`;

export const IconTooltip = styled.span`
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%) translateY(5px);
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease;
`;

export const FilterPopover = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 70px;
  right: 20px;
  ${glassmorphismStyle}
  padding: 16px;
  border-radius: 12px;
  min-width: 250px;
  opacity: ${props => props.isOpen ? 1 : 0};
  visibility: ${props => props.isOpen ? 'visible' : 'hidden'};
  transform: ${props => props.isOpen ? 'translateY(0)' : 'translateY(-10px)'};
  transition: all 0.2s ease;
  z-index: 1000;
`;
