import React, { useState, useEffect, useCallback } from 'react';
import {
  AnotacoesContainer,
  ResponsiveAnotacoesGrid,
  AnotacaoCard,
  AnotacaoTitulo,
  AnotacaoTexto,
  AnotacaoFooter,
  AnotacaoData,
  NovaAnotacaoCard,
  StyledColorPicker,
  StyleButton,
  StyleButtonsContainer,
  FooterControls,
  ColorPickerButton,
  SaveButton,
  FloatingLabel,
  GlassmorphicInput,
  GlassmorphicSelect,
  GlassmorphicDatePicker,
  TitleContainer,
  ColorPickerOverlay,
  DeleteButton,
  FilterIconsContainer,
  FilterIcon,
  IconTooltip,
  FilterPopover,
} from './AnotacoesView.styles';
import {  useToast, Tooltip } from '@chakra-ui/react';
import { FaSearch, FaBold, FaItalic, FaUnderline, FaPalette, FaSave, FaTimes, FaSort } from 'react-icons/fa';
import moment from 'moment';
import { createAnotacao, getAnotacoesByProfissional, Anotacao, CreateAnotacaoDto, deleteAnotacao } from '../../services/anotacoesService';
import { motion, AnimatePresence } from 'framer-motion';
import { useConfirmDialog } from './useConfirmDialog';
import RelevanciaIcon from './RelevanciaIcons';

interface AnotacoesViewProps {
  profissionalId: number;
}

const AnotacoesView: React.FC<AnotacoesViewProps> = ({ profissionalId }) => {
  const [anotacoes, setAnotacoes] = useState<Anotacao[]>([]);
  const [filtro, setFiltro] = useState('');
  const [ordenacao, setOrdenacao] = useState('criado_em');
  const [novaAnotacao, setNovaAnotacao] = useState<CreateAnotacaoDto>({
    titulo: '',
    relevancia: 'Media',
    texto: '',
    data_interesse: '',
    cor: '#fef68a',
  });
  const [isCreating, setIsCreating] = useState(false);
  const [textStyle, setTextStyle] = useState({ bold: false, italic: false, underline: false });
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const toast = useToast();
  const { confirmDialog, ConfirmDialog } = useConfirmDialog();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isSortOpen, setIsSortOpen] = useState(false);

  const fetchAnotacoes = useCallback(async () => {
    try {
      const data = await getAnotacoesByProfissional(profissionalId);
      setAnotacoes(data);
    } catch (error) {
      console.error('Erro ao buscar anotações:', error);
      toast({
        title: 'Erro',
        description: 'Não foi possível carregar as anotações.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  }, [profissionalId, toast]);

  useEffect(() => {
    if (profissionalId) {
      fetchAnotacoes();
    }
  }, [profissionalId, fetchAnotacoes]);

  const handleFiltroChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFiltro(e.target.value);
  };

  const handleOrdenacaoChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setOrdenacao(e.target.value);
  };

  const handleNovaAnotacao = async () => {
    try {
      if (novaAnotacao.titulo && novaAnotacao.texto) {
        await createAnotacao(profissionalId, novaAnotacao);
        setNovaAnotacao({
          titulo: '',
          relevancia: 'Media',
          texto: '',
          data_interesse: '',
          cor: '#fef68a',
        });
        setIsCreating(false);
        fetchAnotacoes();
        toast({
          title: 'Sucesso',
          description: 'Anotação criada com sucesso.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Erro ao criar nova anotação:', error);
      toast({
        title: 'Erro',
        description: 'Não foi possível criar a anotação.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const toggleStyle = (style: 'bold' | 'italic' | 'underline') => {
    setTextStyle(prev => ({ ...prev, [style]: !prev[style] }));
  };

  const anotacoesFiltradas = anotacoes
    .filter(a => a.titulo.toLowerCase().includes(filtro.toLowerCase()) || a.texto.toLowerCase().includes(filtro.toLowerCase()))
    .sort((a, b) => {
      const aValue = a[ordenacao as keyof Anotacao];
      const bValue = b[ordenacao as keyof Anotacao];
      if (typeof aValue === 'string' && typeof bValue === 'string') {
        return moment(bValue).diff(moment(aValue));
      }
      return 0;
    });

  const handleDeleteAnotacao = async (id: number) => {
    const confirmed = await confirmDialog('Tem certeza que deseja deletar esta anotação?');
    if (confirmed) {
      try {
        await deleteAnotacao(id);
        setAnotacoes(prevAnotacoes => prevAnotacoes.filter(a => a.id !== id));
        toast({
          title: 'Success',
          description: 'Note deleted successfully.',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      } catch (error) {
        console.error('Error deleting note:', error);
        toast({
          title: 'Error',
          description: 'Failed to delete the note.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const RelevanciaOption: React.FC<{ value: 'Baixa' | 'Media' | 'Alta' }> = ({ value }) => (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <RelevanciaIcon relevancia={value} size={16} />
      {value}
    </div>
  );

  return (
    <AnotacoesContainer>
      <FilterIconsContainer>
        <FilterIcon onClick={() => setIsFilterOpen(!isFilterOpen)}>
          <FaSearch />
          <IconTooltip className="tooltip">Buscar anotações</IconTooltip>
        </FilterIcon>
        <FilterIcon onClick={() => setIsSortOpen(!isSortOpen)}>
          <FaSort />
          <IconTooltip className="tooltip">Ordenar</IconTooltip>
        </FilterIcon>
      </FilterIconsContainer>

      <FilterPopover isOpen={isFilterOpen}>
        <GlassmorphicInput
          placeholder="Buscar anotações..."
          value={filtro}
          onChange={handleFiltroChange}
        />
      </FilterPopover>

      <FilterPopover isOpen={isSortOpen}>
        <GlassmorphicSelect value={ordenacao} onChange={handleOrdenacaoChange}>
          <option value="criado_em">Data de criação</option>
          <option value="data_interesse">Data de interesse</option>
        </GlassmorphicSelect>
      </FilterPopover>

      <ResponsiveAnotacoesGrid>
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            <NovaAnotacaoCard
              isCreating={isCreating}
              onClick={() => !isCreating && setIsCreating(true)}
              cor={novaAnotacao.cor || '#fef68a'}
            >
              {isCreating ? (
                <>
                  <TitleContainer>
                    <ColorPickerButton onClick={() => setIsColorPickerOpen(!isColorPickerOpen)}>
                      <FaPalette />
                    </ColorPickerButton>
                    <FloatingLabel>
                      <GlassmorphicInput
                        placeholder="Título"
                        value={novaAnotacao.titulo}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNovaAnotacao({ ...novaAnotacao, titulo: e.target.value })}
                      />
                      <span>Título</span>
                    </FloatingLabel>
                  </TitleContainer>
                  <StyleButtonsContainer>
                    <Tooltip label="Negrito">
                      <StyleButton active={textStyle.bold} onClick={() => toggleStyle('bold')}><FaBold /></StyleButton>
                    </Tooltip>
                    <Tooltip label="Itálico">
                      <StyleButton active={textStyle.italic} onClick={() => toggleStyle('italic')}><FaItalic /></StyleButton>
                    </Tooltip>
                    <Tooltip label="Sublinhado">
                      <StyleButton active={textStyle.underline} onClick={() => toggleStyle('underline')}><FaUnderline /></StyleButton>
                    </Tooltip>
                  </StyleButtonsContainer>
                  <AnotacaoTexto
                    as="textarea"
                    value={novaAnotacao.texto}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setNovaAnotacao({ ...novaAnotacao, texto: e.target.value })}
                    style={{
                      fontWeight: textStyle.bold ? 'bold' : 'normal',
                      fontStyle: textStyle.italic ? 'italic' : 'normal',
                      textDecoration: textStyle.underline ? 'underline' : 'none',
                    }}
                  />
                  <FooterControls>
                    <GlassmorphicSelect
                      value={novaAnotacao.relevancia}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setNovaAnotacao({ ...novaAnotacao, relevancia: e.target.value as 'Baixa' | 'Media' | 'Alta' })}
                      width="auto"
                    >
                      <option value="Baixa">
                        <RelevanciaOption value="Baixa" />
                      </option>
                      <option value="Media">
                        <RelevanciaOption value="Media" />
                      </option>
                      <option value="Alta">
                        <RelevanciaOption value="Alta" />
                      </option>
                    </GlassmorphicSelect>
                    <GlassmorphicDatePicker
                      type="date"
                      value={novaAnotacao.data_interesse}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNovaAnotacao({ ...novaAnotacao, data_interesse: e.target.value })}
                      width="auto"
                    />
                    <SaveButton onClick={handleNovaAnotacao}>
                      <FaSave />
                    </SaveButton>
                  </FooterControls>
                </>
              ) : (
                <AnotacaoTitulo>+ Nova Anotação</AnotacaoTitulo>
              )}
            </NovaAnotacaoCard>
            {isColorPickerOpen && (
              <>
                <ColorPickerOverlay onClick={() => setIsColorPickerOpen(false)} />
                <StyledColorPicker
                  color={novaAnotacao.cor}
                  onChange={(color) => setNovaAnotacao({ ...novaAnotacao, cor: color.hex })}
                />
              </>
            )}
          </motion.div>
          {anotacoesFiltradas.map(anotacao => (
            <motion.div
              key={anotacao.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              <AnotacaoCard cor={anotacao.cor}>
                <RelevanciaIcon relevancia={anotacao.relevancia} />
                <DeleteButton onClick={() => handleDeleteAnotacao(anotacao.id)}>
                  <FaTimes />
                </DeleteButton>
                <AnotacaoTitulo>{anotacao.titulo}</AnotacaoTitulo>
                <AnotacaoTexto>{anotacao.texto}</AnotacaoTexto>
                <AnotacaoFooter>
                  <AnotacaoData>{moment(anotacao.criado_em).format('DD/MM/YYYY')}</AnotacaoData>
                </AnotacaoFooter>
              </AnotacaoCard>
            </motion.div>
          ))}
        </AnimatePresence>
      </ResponsiveAnotacoesGrid>
      <ConfirmDialog />
    </AnotacoesContainer>
  );
};

export default AnotacoesView;
