import React, { useState, useRef, useEffect } from 'react';
import { FaBell, FaCheck, FaCheckDouble } from 'react-icons/fa';
import { Notificacao } from '../../services/notificacaoService';
import {
  NotificationDropdownContainer,
  NotificationIcon,
  DropdownContent,
  NotificationList,
  NotificationItem,
  NotificationGroup,
  NotificationGroupHeader,
  AlertBadge,
  EmptyNotification,
  NotificationTitle,
  NotificationMessage,
  NotificationTime,
  MarkAsReadButton,
  NotificationIconWrapper,
  NotificationContent,
  NotificationHeader,
  NotificationFooter,
  ViewAllButton,
} from './Header.styles';

interface NotificationDropdownProps {
  notificacoes: Notificacao[];
  onMarkAllAsRead: () => void;
  onMarkChatAsRead: (chatId: string) => void;
  onNotificationClick: (notification: Notificacao) => void;
  onMarkAsRead: (notificationId: number) => void;
}

const NotificationDropdown: React.FC<NotificationDropdownProps> = ({
  notificacoes,
  onMarkAllAsRead,
  onMarkChatAsRead,
  onNotificationClick,
  onMarkAsRead,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => setIsOpen(!isOpen);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const groupedNotificacoes = notificacoes.reduce((acc, notificacao) => {
    if (!acc[notificacao.chat_id]) {
      acc[notificacao.chat_id] = [];
    }
    acc[notificacao.chat_id].push(notificacao);
    return acc;
  }, {} as Record<string, Notificacao[]>);

  const unreadCount = notificacoes.filter(n => !n.lida).length;

  const formatTime = (date: string) => {
    const now = new Date();
    const notificationDate = new Date(date);
    const diffInMinutes = Math.floor((now.getTime() - notificationDate.getTime()) / (1000 * 60));

    if (diffInMinutes < 60) {
      return `${diffInMinutes}m`;
    } else if (diffInMinutes < 1440) {
      return `${Math.floor(diffInMinutes / 60)}h`;
    } else {
      return notificationDate.toLocaleDateString();
    }
  };

  const getNotificationIcon = (tipo: string) => {
    switch (tipo) {
      case 'mensagem':
        return '💬';
      case 'agendamento':
        return '📅';
      case 'alerta':
        return '⚠️';
      default:
        return '🔔';
    }
  };

  return (
    <NotificationDropdownContainer ref={dropdownRef}>
      <NotificationIcon onClick={toggleDropdown}>
        <FaBell size={24} color="#fff" />
        {unreadCount > 0 && <AlertBadge>{unreadCount}</AlertBadge>}
      </NotificationIcon>
      {isOpen && (
        <DropdownContent>
          <NotificationHeader>
            <h2>Notificações</h2>
            {unreadCount > 0 && (
              <MarkAsReadButton onClick={onMarkAllAsRead}>
                <FaCheckDouble /> Marcar todas como lidas
              </MarkAsReadButton>
            )}
          </NotificationHeader>
          <NotificationList>
            {Object.entries(groupedNotificacoes).length > 0 ? (
              Object.entries(groupedNotificacoes).map(([chatId, chatNotificacoes]) => (
                <NotificationGroup key={chatId}>
                  <NotificationGroupHeader>
                    <h3>{chatNotificacoes[0].pushName}</h3>
                    {chatNotificacoes.some(n => !n.lida) && (
                      <MarkAsReadButton onClick={() => onMarkChatAsRead(chatId)}>
                        <FaCheck /> Marcar chat como lido
                      </MarkAsReadButton>
                    )}
                  </NotificationGroupHeader>
                  {chatNotificacoes.filter(n => !n.lida).map((notificacao) => (
                    <NotificationItem 
                      key={notificacao.id} 
                      onClick={() => {
                        onNotificationClick(notificacao);
                        onMarkAsRead(notificacao.id);
                      }}
                      $isRead={notificacao.lida}
                    >
                      <NotificationIconWrapper>
                        {getNotificationIcon(notificacao.tipo)}
                      </NotificationIconWrapper>
                      <NotificationContent>
                        <NotificationTitle>{notificacao.tipo}</NotificationTitle>
                        <NotificationMessage>{notificacao.evento}</NotificationMessage>
                        <NotificationTime>{formatTime(notificacao.criado_em)}</NotificationTime>
                      </NotificationContent>
                    </NotificationItem>
                  ))}
                </NotificationGroup>
              ))
            ) : (
              <EmptyNotification>Nenhuma notificação no momento.</EmptyNotification>
            )}
          </NotificationList>
          <NotificationFooter>
            <ViewAllButton onClick={() => {/* Implement view all notifications */}}>
              Ver todas as notificações
            </ViewAllButton>
          </NotificationFooter>
        </DropdownContent>
      )}
    </NotificationDropdownContainer>
  );
};

export default NotificationDropdown;
