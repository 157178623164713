import axios from 'axios';
import Config from '../config/Config';

const API_URL = Config.API_URL;

export interface Anotacao {
  id: number;
  titulo: string;
  relevancia: 'Baixa' | 'Media' | 'Alta';
  criado_em: string;
  data_interesse: string;
  texto: string;
  cor: string;
  profissional: {
    id_profissional: number;
    nome_profissional: string;
  };
}

export interface CreateAnotacaoDto {
  titulo: string;
  relevancia: 'Baixa' | 'Media' | 'Alta';
  data_interesse?: string;
  texto?: string;
  cor?: string;
}

export interface UpdateAnotacaoDto {
  titulo?: string;
  relevancia?: 'Baixa' | 'Media' | 'Alta';
  data_interesse?: string;
  texto?: string;
  cor?: string;
}

export const createAnotacao = async (profissionalId: number, anotacao: CreateAnotacaoDto): Promise<Anotacao> => {
  const response = await axios.post(`${API_URL}/api/anotacoes/${profissionalId}`, anotacao);
  return response.data;
};

export const getAnotacoesByProfissional = async (profissionalId: number): Promise<Anotacao[]> => {
  const response = await axios.get(`${API_URL}/api/anotacoes/profissional/${profissionalId}`);
  return response.data;
};

export const getAnotacao = async (id: number): Promise<Anotacao> => {
  const response = await axios.get(`${API_URL}/api/anotacoes/${id}`);
  return response.data;
};

export const updateAnotacao = async (id: number, anotacao: UpdateAnotacaoDto): Promise<Anotacao> => {
  const response = await axios.put(`${API_URL}/api/anotacoes/${id}`, anotacao);
  return response.data;
};

export const deleteAnotacao = async (id: number): Promise<void> => {
  await axios.delete(`${API_URL}/api/anotacoes/${id}`);
};
