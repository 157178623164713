import axios from 'axios';
import Config from 'config/Config';

const API_URL = Config.API_URL;

export interface Agenda {
  id_agenda: number;
  data_inicio: Date;
  data_fim: Date;
  titulo: string;
  descricao?: string;
  origem?: string;
  cor?: string;
  convenio?: string;
  status_confirmacao?: string;
  data_confirmacao?: Date;
  fone_contato?: string;
  profissional: {
    id_profissional: number;
    nome_profissional: string;
  };
}

export interface CreateAgendaDto {
  data_inicio: Date;
  data_fim: Date;
  titulo: string;
  descricao?: string;
  origem?: string;
  cor?: string;
  convenio?: string;
  status_confirmacao?: string;
  data_confirmacao?: Date;
  fone_contato?: string;
}

export interface UpdateAgendaDto {
  data_inicio?: Date;
  data_fim?: Date;
  titulo?: string;
  descricao?: string;
  origem?: string;
  cor?: string;
  convenio?: string;
  status_confirmacao?: string;
  data_confirmacao?: Date;
  fone_contato?: string;
}

export const fetchAgendas = async (): Promise<Agenda[]> => {
  const response = await axios.get<Agenda[]>(`${API_URL}/api/agendas`);
  return response.data;
};

export const fetchAgendasByProfissional = async (id_profissional: number): Promise<Agenda[]> => {
  const response = await axios.get<Agenda[]>(`${API_URL}/api/agendas/profissional/${id_profissional}`);
  return response.data;
};

export const createAgenda = async (id_profissional: number, createAgendaDto: CreateAgendaDto): Promise<Agenda> => {
  const response = await axios.post<Agenda>(`${API_URL}/api/agendas/${id_profissional}`, createAgendaDto);
  return response.data;
};

export const updateAgenda = async (id: number, updateAgendaDto: UpdateAgendaDto): Promise<Agenda> => {
  const response = await axios.patch<Agenda>(`${API_URL}/api/agendas/${id}`, updateAgendaDto);
  return response.data;
};

export const deleteAgenda = async (id: number): Promise<void> => {
  await axios.delete(`${API_URL}/api/agendas/${id}`);
};
