import {create} from 'zustand';
import { Notificacao, notificacaoService } from '../services/notificacaoService';

interface NotificacaoState {
  notificacoes: Notificacao[];
  fetchNotificacoes: (id_clinica: string) => Promise<void>;
  marcarComoLida: (id: number) => Promise<void>;
  marcarTodasComoLidas: (id_clinica: string) => Promise<void>;
  marcarChatComoLido: (chatId: string) => Promise<void>;
  adicionarNotificacao: (notificacao: Notificacao) => void;
}

export const useNotificacaoStore = create<NotificacaoState>((set) => ({
  notificacoes: [],
  fetchNotificacoes: async (id_clinica: string) => {
    const notificacoes = await notificacaoService.getNotificacoes(id_clinica);
    set({ notificacoes });
  },
  marcarComoLida: async (id: number) => {
    await notificacaoService.marcarComoLida(id);
    set((state) => ({
      notificacoes: state.notificacoes.filter((n) => n.id !== id),
    }));
  },
  marcarTodasComoLidas: async (id_clinica: string) => {
    await notificacaoService.marcarTodasComoLidas(id_clinica);
    set({ notificacoes: [] });
  },
  marcarChatComoLido: async (chatId: string) => {
    await notificacaoService.marcarChatComoLido(chatId);
    set((state) => ({
      notificacoes: state.notificacoes.filter((n) => n.chat_id !== chatId),
    }));
  },
  adicionarNotificacao: (notificacao: Notificacao) => {
    set((state) => ({
      notificacoes: [notificacao, ...state.notificacoes],
    }));
  },
}));
