import React, { useState, useRef, useEffect } from 'react';
import { Avatar, Box, Typography, IconButton, InputBase, Tooltip, Menu, MenuItem, Button, Paper } from '@mui/material';
import { styled } from '@mui/system';
import { BsThreeDotsVertical, BsSend, BsArrowDownCircle, BsEmojiSmile, BsPaperclip } from 'react-icons/bs';
import { MdArrowBack, MdCheckCircle, MdReplay } from 'react-icons/md';
import ViewChatModal from '../componentes/ViewChatModal';
import { Chat, ChatMessage } from '../types';
import whatsAppMultTicketService from 'services/whatsAppMultTicketService';

const lightHeaderColor = '#8DA4CC';

interface RightContainerProps {
  chat: Chat | null;
  onSetPending: (id: string) => Promise<void>;
  onResolveChat: (id: string) => Promise<void>;
  onReopenChat: (id: string) => Promise<void>;
  onSendMessage: (chatId: string, message: string) => Promise<void>;
  tabIndex: number;
  onClearCurrentChat: () => void;
  isSidebarOpen: boolean;
}

const MessageBubble = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'isAttendant'
})<{ isAttendant: boolean }>(({ theme, isAttendant }) => ({
  marginBottom: '10px',
  maxWidth: '70%',
  padding: '10px',
  borderRadius: '12px',
  position: 'relative',
  backgroundColor: isAttendant ? 'rgba(141, 164, 204, 0.2)' : '#ffffff',
  boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
  alignSelf: isAttendant ? 'flex-end' : 'flex-start',
  color: '#000000',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '10px',
    [isAttendant ? 'right' : 'left']: '-8px',
    borderWidth: '8px',
    borderStyle: 'solid',
    borderColor: isAttendant
      ? 'transparent rgba(141, 164, 204, 0.2) transparent transparent'
      : 'transparent transparent transparent #ffffff',
  },
}));

const ChatContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
});

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  flexGrow: 1, 
  padding: '12px', 
  backgroundColor: '#fff', 
  borderRadius: '24px', 
  marginRight: '10px',
  '&:focus-within': {
    boxShadow: `0 0 0 1px ${lightHeaderColor}`,
  }
}));

const formatMessage = (text: string) => {
  text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  text = text.replace(/\*(.*?)\*/g, '<em>$1</em>');
  text = text.replace(/~~(.*?)~~/g, '<del>$1</del>');
  text = text.replace(/```(.*?)```/g, '<code>$1</code>');
  text = text.replace(/\n/g, '<br />');
  return text;
};

const RightContainer: React.FC<RightContainerProps> = ({ 
  chat, 
  onSetPending, 
  onResolveChat, 
  onReopenChat, 
  onSendMessage, 
  tabIndex,
  onClearCurrentChat,
  isSidebarOpen
}) => {
  const [message, setMessage] = useState('');
  const chatEndRef = useRef<HTMLDivElement>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [sending, setSending] = useState(false);
  const [profilePicture, setProfilePicture] = useState<string | null>(null);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);

  const handleMenuClose = () => setAnchorEl(null);

  const handleSendMessage = async () => {
    if (chat && message.trim() && !sending) {
      setSending(true);
      await onSendMessage(chat.id, message);
      setMessage('');
      setSending(false);
    }
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const scrollToBottom = () => chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });

  useEffect(() => {
    if (chat) {
      setMessages(chat.messages);
      scrollToBottom();
    }
  }, [chat]);

  useEffect(() => {
    const fetchProfilePicture = async () => {
      if (chat && chat.session) {
        try {
          console.log('Fetching profile picture for session:', chat.session.id, 'and user:', chat.userId);
          const picture = await whatsAppMultTicketService.getProfilePicture(chat.session.id, chat.userId);
          if (picture) {
            setProfilePicture(picture);
          } else {
            console.warn('No profile picture returned for user:', chat.userId);
          }
        } catch (error) {
          console.error('Failed to fetch profile picture', error);
        }
      }
    };

    fetchProfilePicture();
  }, [chat]);

  const handleViewChat = () => setModalOpen(true);

const handleSetPending = () => {
  if (chat) {
    onSetPending(chat.id);
    onClearCurrentChat();
  }
};

const handleResolveChat = () => {
  if (chat) {
    onResolveChat(chat.id);
    onClearCurrentChat();
  }
};

  const handleReopenChat = () => {
    if (chat) {
      onReopenChat(chat.id);
      onClearCurrentChat();
    }
  };

  if (!chat) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100%', 
        width: '100%',
        backgroundColor: '#f0f0f0',
      }}>
        <Typography variant="h6" color="textSecondary">Selecione um ticket para iniciar a conversa.</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      height: '100%', 
      width: '100%', 
      bgcolor: '#f0f0f0',
    }}>
      <Box sx={{ display: 'flex', alignItems: 'center', padding: '16px', backgroundColor: lightHeaderColor, color: 'white' }}>
        <Avatar 
          alt={chat?.userName} 
          src={profilePicture || chat?.userImage || '/default-avatar.png'} 
          sx={{ width: 40, height: 40, marginRight: 2 }}
          onError={(e) => {
            const target = e.target as HTMLImageElement;
            target.onerror = null;
            target.src = '/default-avatar.png';
          }}
        />
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>{chat?.userName}</Typography>
          <Typography variant="body2" sx={{ opacity: 0.7 }}>
            {chat?.status === 'open' ? 'Online' : 'Offline'}
          </Typography>
        </Box>
        <Box>
          {tabIndex === 1 ? (
            <Button
              variant="contained"
              startIcon={<MdReplay />}
              onClick={handleReopenChat}
              sx={{ bgcolor: '#128C7E', '&:hover': { bgcolor: '#075E54' } }}
            >
              Reabrir
            </Button>
          ) : (
            <>
              <Tooltip title="Marcar como Pendente" arrow>
                <IconButton onClick={handleSetPending} sx={{ color: 'white' }}>
                  <MdArrowBack />
                </IconButton>
              </Tooltip>
              <Tooltip title="Marcar como Resolvido" arrow>
                <IconButton onClick={handleResolveChat} sx={{ color: 'white' }}>
                  <MdCheckCircle />
                </IconButton>
              </Tooltip>
              <IconButton onClick={handleMenuOpen} sx={{ color: 'white' }}>
                <BsThreeDotsVertical />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem onClick={handleMenuClose}>Excluir</MenuItem>
                <MenuItem onClick={handleViewChat}>Ver Conversa</MenuItem>
              </Menu>
            </>
          )}
        </Box>
      </Box>
      <ChatContainer sx={{ 
        flexGrow: 1, 
        position: 'relative', 
        overflowY: 'auto', 
        backgroundImage: `url(/wa-background-light.png)`, 
        backgroundSize: 'cover', 
        padding: '16px', 
        scrollbarWidth: 'thin', 
        '&::-webkit-scrollbar': { width: '6px' }, 
        '&::-webkit-scrollbar-thumb': { backgroundColor: 'rgba(0,0,0,.2)' } 
      }}>
        {messages.map((message, index) => (
          message && message.id && (
            <MessageBubble key={`${chat.id}-${message.id}-${index}`} isAttendant={message.from !== chat.userId}>
              <Typography 
                variant="body1" 
                color="textPrimary"
                dangerouslySetInnerHTML={{ __html: formatMessage(message.body) }}
              />
              <Typography variant="caption" display="block" color="textSecondary" sx={{ mt: 1, textAlign: 'right' }}>
                {new Date(message.timestamp).toLocaleString()}
              </Typography>
            </MessageBubble>
          )
        ))}
        <div ref={chatEndRef} />
        <Tooltip title="Rolar para o final" arrow>
          <IconButton 
            sx={{ 
              position: 'absolute', 
              bottom: '20px', 
              right: '20px', 
              backgroundColor: 'rgba(255, 255, 255, 0.8)', 
              '&:hover': { backgroundColor: 'rgba(255, 255, 255, 1)' } 
            }} 
            onClick={scrollToBottom}
          >
            <BsArrowDownCircle />
          </IconButton>
        </Tooltip>
      </ChatContainer>
      <Box sx={{ display: 'flex', alignItems: 'center', padding: '16px', backgroundColor: '#f0f0f0' }}>
        <IconButton sx={{ mr: 1 }}>
          <BsEmojiSmile />
        </IconButton>
        <IconButton sx={{ mr: 1 }}>
          <BsPaperclip />
        </IconButton>
        <StyledInputBase
          placeholder="Digite uma mensagem"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          multiline
          maxRows={4}
        />
        <IconButton onClick={handleSendMessage} disabled={sending} sx={{ bgcolor: lightHeaderColor, color: 'white', '&:hover': { bgcolor: '#7B93B8' } }}>
          <BsSend />
        </IconButton>
      </Box>
      <ViewChatModal open={modalOpen} onClose={() => setModalOpen(false)} chat={chat} />
    </Box>
  );
};

export default RightContainer;