import React, { useState, useEffect, useRef, useCallback } from 'react';
import { FaStethoscope, FaCog, FaBars, FaSignOutAlt, FaClipboardList, FaArrowRight, FaCalendarAlt, FaQuestionCircle, FaClinicMedical, FaUser, FaUserMd, FaServer, FaChartLine, FaFileInvoiceDollar, FaReceipt, FaRobot, FaTimes } from 'react-icons/fa';
import { useNotificacaoStore } from '../../store/notificacaoStore';
import {
  HeaderContainer,
  LogoContainer,
  MenuIcon,
  RightIcons,
  Icon,
  IconWrapper,
  CustomTooltip,
  Profile,
  Sidebar,
  SidebarOverlay,
  MenuItem,
  SidebarTooltip,
  MenuSection,
  MenuTitle,
  SettingsBox,
  TrialContainer,
  TrialTextLeft,
  TrialTextCenter,
  NotificationModal,
  NotificationList,
  NotificationItem,
  NotificationGroup,
  NotificationGroupHeader,
  NotificationActions,
  NotificationDetailModal,
} from './Header.styles';
import { useAuth } from 'context/authContext';
import SettingsMenu from 'components/SettingsMenu';
import { useNavigate } from 'react-router-dom';
import { useSocket } from '../../context/SocketContext';
import { Notificacao } from '../../services/notificacaoService';
import NotificationDropdown from './NotificationDropdown';

interface HeaderProps {
  theme: string;
  toggleTheme: () => void;
}

interface UserData {
  id_users: string;
  nome_usuario: string;
  email_usuario: string;
  url_imagem: string;
  status_usuario: string;
  privilegio_usuario: string;
  id_clinica: string;
}

const Header: React.FC<HeaderProps> = ({ theme, toggleTheme }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [activeTooltip, setActiveTooltip] = useState<string | null>(null);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const settingsRef = useRef<HTMLDivElement>(null);
  const { logout, userData, setUserData } = useAuth();
  const { notificacoes, fetchNotificacoes, marcarComoLida, marcarTodasComoLidas, marcarChatComoLido, adicionarNotificacao } = useNotificacaoStore();
  const { socket } = useSocket();  // Desestruture para obter o socket diretamente
  const navigate = useNavigate();

  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState<Notificacao | null>(null);

  useEffect(() => {
    if (userData) {
      fetchNotificacoes(userData.id_clinica);
      const interval = setInterval(() => fetchNotificacoes(userData.id_clinica), 600000); // 10 minutes
      return () => clearInterval(interval);
    }
  }, [fetchNotificacoes, userData]);

  useEffect(() => {
    if (socket && userData) {
      const handleNotification = (notification: Notificacao) => {
        adicionarNotificacao(notification);
      };

      socket.on('notification', handleNotification);

      return () => {
        socket.off('notification', handleNotification);
      };
    }
  }, [socket, adicionarNotificacao, userData]);

  const handleMenuClick = useCallback((path: string) => {
    navigate(path);
    setSidebarOpen(false);
  }, [navigate]);

  const handleLogout = useCallback(() => {
    logout();
  }, [logout]);

  const handleUserDataUpdate = useCallback((updatedUserData: UserData) => {
    setUserData(updatedUserData);
  }, [setUserData]);

  const handleNotificationClick = (notification: Notificacao) => {
    setSelectedNotification(notification);
  };

  const handleCloseNotificationDetail = () => {
    setSelectedNotification(null);
  };

  const handleMarkAsRead = async (id: number) => {
    await marcarComoLida(id);
  };
  const handleMarkAllAsRead = async () => {
    if (userData) {
      await marcarTodasComoLidas(userData.id_clinica);
    }
  };

  const handleMarkChatAsRead = async (chatId: string) => {
    await marcarChatComoLido(chatId);
  };

  const groupedNotificacoes = notificacoes.reduce((acc, notificacao) => {
    if (!acc[notificacao.chat_id]) {
      acc[notificacao.chat_id] = [];
    }
    acc[notificacao.chat_id].push(notificacao);
    return acc;
  }, {} as Record<string, Notificacao[]>);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target as Node)) {
        setSidebarOpen(false);
      }
      if (settingsRef.current && !settingsRef.current.contains(event.target as Node) && !modalOpen) {
        setSettingsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [sidebarRef, settingsRef, modalOpen]);

  if (!userData) {
    return null;
  }

  const userImage = userData.url_imagem ? `${process.env.REACT_APP_API_URL}${userData.url_imagem}` : 'perfil_default.jpeg';
  const userName = userData.nome_usuario || '';
  const userPrivilege = userData.privilegio_usuario || '';

  const isAdmin = userPrivilege.toLowerCase() === 'administrador';

  const trialEndFormatted = userData.stripeStatus?.trialEnd
    ? new Date(userData.stripeStatus.trialEnd).toLocaleDateString('pt-BR')
    : '';

  const renderIcon = (icon: React.ReactNode, label: string, onClick?: () => void) => (
    <IconWrapper
      onMouseEnter={() => setActiveTooltip(label)}
      onMouseLeave={() => setActiveTooltip(null)}
    >
      <Icon onClick={onClick}>
        {icon}
      </Icon>
      <CustomTooltip $isVisible={activeTooltip === label}>
        {label}
      </CustomTooltip>
    </IconWrapper>
  );


  const renderMenuItem = (icon: React.ReactNode, label: string, path: string, description: string) => (
    <MenuItem
      onClick={() => handleMenuClick(path)}
      onMouseEnter={() => setActiveTooltip(label)}
      onMouseLeave={() => setActiveTooltip(null)}
    >
      {icon}
      {label}
      <SidebarTooltip $isVisible={activeTooltip === label}>
        {description}
      </SidebarTooltip>
    </MenuItem>
  );

  const renderMenuSection = (title: string, items: Array<{ icon: React.ReactNode, label: string, path: string, description: string }>) => (
    <MenuSection>
      <MenuTitle>{title}</MenuTitle>
      {items.map((item) => renderMenuItem(item.icon, item.label, item.path, item.description))}
    </MenuSection>
  );

  return (
    <>
      {userData.stripeStatus?.status === 'trialing' && (
        <TrialContainer>
          <TrialTextLeft>
            Modo de teste até {trialEndFormatted}
          </TrialTextLeft>
          <TrialTextCenter onClick={() => handleMenuClick('/planos')}>
            <FaFileInvoiceDollar style={{ marginRight: '5px' }} />
            Conheça nossos planos
            <FaArrowRight style={{ marginLeft: '5px' }} />
          </TrialTextCenter>
        </TrialContainer>
      )}
      <HeaderContainer>
        <MenuIcon onClick={() => setSidebarOpen(!sidebarOpen)}>
          <FaBars size={24} color="#fff" />
        </MenuIcon>
        <LogoContainer>
          <img src="/minner-icon.png" alt="Logo" />
          <span>Minner AI</span>
        </LogoContainer>
        <RightIcons>
          <NotificationDropdown
            notificacoes={notificacoes.filter(n => !n.lida)}
            onMarkAllAsRead={handleMarkAllAsRead}
            onMarkChatAsRead={handleMarkChatAsRead}
            onNotificationClick={handleNotificationClick}
            onMarkAsRead={handleMarkAsRead}
          />
          {renderIcon(<FaCog size={24} color="#fff" />, "Configurações", () => setSettingsOpen(!settingsOpen))}
          {renderIcon(
            <Profile>
              <img src={userImage} alt="Profile" />
            </Profile>,
            userName
          )}
          {renderIcon(<FaSignOutAlt size={24} color="#fff" />, "Sair", handleLogout)}
        </RightIcons>
        <SidebarOverlay $isOpen={sidebarOpen} onClick={() => setSidebarOpen(false)} />
        <Sidebar $isOpen={sidebarOpen} ref={sidebarRef}>
          <MenuIcon onClick={() => setSidebarOpen(false)} style={{ padding: '20px', display: 'flex', justifyContent: 'flex-end' }}>
            <FaTimes size={24} color="#333" />
          </MenuIcon>
          {renderMenuSection('ATENDIMENTO', [
            { icon: <FaClipboardList />, label: "Atendimento", path: '/atendimento', description: "Gerencie atendimentos e consultas" },
            { icon: <FaCalendarAlt />, label: "Agendas", path: '/agendas', description: "Visualize e gerencie agendamentos" },
            { icon: <FaRobot />, label: "Chat IA Secretária", path: '/chat-secretaria', description: "Interaja com a IA assistente" },
            { icon: <FaQuestionCircle />, label: "Ajuda", path: '/ajuda', description: "Obtenha suporte e informações" },
          ])}
          {isAdmin && (
            <>
              {renderMenuSection('GESTÃO', [
                { icon: <FaClinicMedical />, label: "Clínica", path: '/clinica', description: "Gerencie informações da clínica" },
                { icon: <FaUserMd />, label: "Profissionais", path: '/profissionais', description: "Gerencie profissionais de saúde" },
                { icon: <FaStethoscope />, label: "Especialidades", path: '/especialidades', description: "Gerencie especialidades médicas" },
                { icon: <FaUser />, label: "Usuários", path: '/usuarios', description: "Gerencie contas de usuários" },
                { icon: <FaChartLine />, label: "Dashboard", path: '/dashboard', description: "Visualize estatísticas e métricas" },
                { icon: <FaServer />, label: "Instâncias", path: '/instancias', description: "Gerencie instâncias do sistema" },
              ])}
              {renderMenuSection('ADMINISTRAÇÃO', [
                { icon: <FaFileInvoiceDollar />, label: "Assinatura", path: '/subscription', description: "Gerencie sua assinatura" },
                { icon: <FaReceipt />, label: "Planos", path: '/planos', description: "Visualize planos disponíveis" },
              ])}
            </>
          )}
        </Sidebar>
        {settingsOpen && (
          <SettingsBox ref={settingsRef}>
            <SettingsMenu
              setModalOpen={setModalOpen}
              onUserDataUpdate={handleUserDataUpdate}
              setSettingsOpen={setSettingsOpen}
              handleMenuClick={handleMenuClick}
            />
          </SettingsBox>
        )}
      </HeaderContainer>

      <NotificationModal isOpen={notificationModalOpen} onClose={() => setNotificationModalOpen(false)}>
        <h2>Notificações</h2>
        <NotificationActions>
          <button onClick={handleMarkAllAsRead}>Marcar todas como lidas</button>
        </NotificationActions>
        <NotificationList>
          {Object.entries(groupedNotificacoes).map(([chatId, chatNotificacoes]) => (
            <NotificationGroup key={chatId}>
              <NotificationGroupHeader>
                <h3>{chatNotificacoes[0].pushName}</h3>
                <button onClick={() => handleMarkChatAsRead(chatId)}>Marcar chat como lido</button>
              </NotificationGroupHeader>
              {chatNotificacoes.filter(n => !n.lida).map((notificacao) => (
                <NotificationItem 
                  key={notificacao.id} 
                  onClick={() => handleNotificationClick(notificacao)}
                  $isRead={notificacao.lida}
                >
                  <p>{notificacao.evento}</p>
                  <small>{new Date(notificacao.criado_em).toLocaleString()}</small>
                </NotificationItem>
              ))}
            </NotificationGroup>
          ))}
        </NotificationList>
      </NotificationModal>

      <NotificationDetailModal isOpen={!!selectedNotification} onClose={handleCloseNotificationDetail}>
        {selectedNotification && (
          <>
            <h2>{selectedNotification.tipo}</h2>
            <p><strong>Evento:</strong> {selectedNotification.evento}</p>
            <p><strong>Chat:</strong> {selectedNotification.pushName}</p>
            <p><strong>Data:</strong> {new Date(selectedNotification.criado_em).toLocaleString()}</p>
            <button onClick={() => handleMarkAsRead(selectedNotification.id)}>Marcar como lida</button>
          </>
        )}
      </NotificationDetailModal>
    </>
  );
};

export default Header;
