import React, { useEffect, useState } from 'react';
import { getProducts } from 'services/stripeService';
import { Product } from './types';
import PricingCard from 'components/PricingCard';
import { Box, Grid, Heading, Text, Stack, Badge, Radio, RadioGroup } from '@chakra-ui/react';

const PlanosModule: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [isAnnual, setIsAnnual] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsList: Product[] = await getProducts(); // Tipagem explícita
        setProducts(productsList); // Atribui os produtos recebidos ao estado
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  return (
    <Box padding="16px">
      <Heading as="h2" size="lg" textAlign="center" mb="8">
        Renove sua assinatura hoje.
      </Heading>
      <Text textAlign="center" mb="8">
        Não deixe sua assinatura Minner Clínicas Médicas acabar. Mantenha sua área de trabalho, Web e aplicativos móveis completos.
      </Text>
      <Box display="flex" justifyContent="center" mb="8">
        <RadioGroup onChange={() => setIsAnnual(!isAnnual)} value={isAnnual ? "anual" : "mensal"} defaultValue="mensal">
          <Stack direction="row" spacing={4} align="center">
            <Radio value="anual" borderRadius="full" bg="white" _checked={{ bg: "blue.500", color: "white", borderColor: "blue.500" }}>
              Anual
            </Radio>
            {isAnnual && <Badge colorScheme="yellow">Economize</Badge>}
            <Radio value="mensal" borderRadius="full" bg="white" _checked={{ bg: "blue.500", color: "white", borderColor: "blue.500" }}>
              Mensalmente
            </Radio>
          </Stack>
        </RadioGroup>
      </Box >
      <Grid templateColumns="repeat(auto-fit, minmax(250px, 1fr))" gap={6}>
        {products.length > 0 ? (
          products
            .filter(product => product.stripeProductId !== 'prod_QjrIKprLDUvLJX')
            .map(product => (
              <PricingCard key={product.id} product={product} isAnnual={isAnnual} />
            ))
        ) : (
          <Text>Nenhum produto disponível no momento.</Text>
        )}
      </Grid>
    </Box>
  );
};

export default PlanosModule;
