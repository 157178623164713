import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.div<{ relevancia: string }>`
  position: absolute;
  top: -12px;
  right: -12px;
  width: 24px;
  height: 24px;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));
`;

const RelevanciaIcon: React.FC<{ relevancia: 'Baixa' | 'Media' | 'Alta'; size?: number }> = ({ relevancia, size = 24 }) => {
  const color = {
    Baixa: '#4CAF50',
    Media: '#FFC107',
    Alta: '#F44336',
  }[relevancia];

  const getPathD = () => {
    switch (relevancia) {
      case 'Baixa':
        return "M4 18h16M4 12h8";
      case 'Media':
        return "M4 18h16M4 12h12";
      case 'Alta':
        return "M4 18h16M4 12h16M4 6h16";
    }
  };

  return (
    <IconWrapper relevancia={relevancia}>
      <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d={getPathD()} stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    </IconWrapper>
  );
};

export default RelevanciaIcon;
