import styled, { css } from 'styled-components';
import { Input, InputGroup, InputLeftElement, Select } from '@chakra-ui/react';
import CustomButton from 'components/CustomButton';
import { Button } from '@chakra-ui/react';
import { Tabs, Tab } from '@chakra-ui/react';
import { ChromePicker } from 'react-color';

export const AgendasContainer = styled.div`
  display: flex;
  height: calc(100vh - 60px); // Assuming header height is 60px
  width: 100%;
  background-color: #f7f8fc;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const SearchEventContainer = styled.div`
  margin-bottom: 20px;
  width: 100%;
  max-width: 400px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const SearchEventInput = styled(InputGroup)`
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

  input {
    border: 1px solid #e2e8f0;
    &:focus {
      border-color: #4299e1;
      box-shadow: 0 0 0 1px #4299e1;
    }
  }
`;
export const SidebarContainer = styled.div<{ isOpen: boolean }>`
  width: ${(props) => (props.isOpen ? '350px' : '0')};
  height: 100%;
  padding: ${(props) => (props.isOpen ? '20px' : '0')};
  border-right: 1px solid #e2e8f0;
  display: flex;
  flex-direction: column;
  background-color: white;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  overflow: hidden;

  @media (max-width: 768px) {
    position: absolute;
    z-index: 1000;
    width: ${(props) => (props.isOpen ? '100%' : '0')};
    height: calc(100% - 60px);
  }
`;

export const CalendarContainer = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

export const CalendarWrapper = styled.div`
  height: 100%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  
  .rbc-calendar {
    font-family: 'Arial', sans-serif;
  }
  .rbc-header {
    padding: 10px;
    font-weight: bold;
    background-color: #f7fafc;
  }
  .rbc-event {
    border-radius: 4px;
    padding: 2px 5px;
  }
  .rbc-today {
    background-color: #ebf8ff;
  }
  .rbc-toolbar button {
    color: #4a5568;
    border-radius: 4px;
    &:hover {
      background-color: #edf2f7;
    }
    &:active {
      background-color: #e2e8f0;
    }
  }

  @media (max-width: 768px) {
    padding: 10px;
    .rbc-toolbar {
      flex-direction: column;
      align-items: stretch;
      .rbc-toolbar-label {
        margin: 10px 0;
      }
    }
    .rbc-month-view, .rbc-time-view {
      height: calc(100vh - 200px);
    }
  }
`;

export const CalendarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
  }
`;

export const NewAppointmentButton = styled(CustomButton)`
  background-color: #4299e1;
  color: white;
  &:hover {
    background-color: #3182ce;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

// The rest of the styled components remain unchanged
export const Footer = styled.div`
  font-size: 14px;
  text-align: left;
  padding: 10px;
  border-top: 1px solid #e2e8f0;
  margin-top: auto;
`;

export const SearchInput = styled(InputGroup)`
  margin-bottom: 15px;
`;

export const FiltersWrapper = styled.div`
  margin-bottom: 20px;
`;

export const RadioWrapper = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 10px;
  justify-content: flex-start;
`;

export const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  & > input {
    display: none;
  }
  & > svg {
    color: #ccc;
    transition: color 0.2s ease;
  }
  & > input:checked + svg {
    color: #4299e1;
  }
  &:hover > svg {
    color: #63b3ed;
  }
`;

export const AgendaList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  max-height: calc(100% - 150px);
  flex-grow: 1;
`;

export const AgendaItem = styled.div<{ isSelected?: boolean }>`
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e2e8f0;
  background-color: ${props => props.isSelected ? '#ebf8ff' : 'transparent'};
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: #e6fffa;
  }
`;

export const AgendaItemProfile = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
  object-fit: cover;
  border: 2px solid #e2e8f0;
`;

export const AgendaItemName = styled.span`
  flex: 1;
  font-size: 14px;
  font-weight: 500;
  color: #2d3748;
`;

export const AgendaItemStatus = styled.div`
  display: flex;
  align-items: center;
`;

export const Flag = styled.div<{ $status: string }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ $status }) =>
    $status === 'ativo' ? '#48bb78' : '#f56565'};
  margin-left: 10px;
`;

export const ProfissionalInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledInput = styled(Input)`
  background-color: white;
  border-color: #e2e8f0;
  &:focus {
    border-color: #4299e1;
    box-shadow: 0 0 0 1px #4299e1;
  }
`;

export const StyledInputLeftElement = styled(InputLeftElement)`
  color: #a0aec0;
`;

export const EventTitle = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;

export const EventDescription = styled.div`
  font-size: 12px;
  color: #718096;
`;

export const EventTime = styled.div`
  font-size: 12px;
  color: #4a5568;
  margin-top: 5px;
`;

export const TooltipContent = styled.div`
  padding: 8px;
  max-width: 200px;
`;

export const SidebarToggleButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  margin-right: 10px;
  svg {
    color: #4a5568;
    font-size: 20px;
  }
`;

export const SidebarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e2e8f0;
`;

export const SidebarCloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #4a5568;
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

export const LegendContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 15px;
`;

export const LegendColor = styled.div<{ color: string }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${props => props.color};
  margin-right: 5px;
`;

export const LegendText = styled.span`
  font-size: 12px;
  color: #4a5568;
`;

export const AgendaViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  @media (max-width: 768px) {
    height: calc(100vh - 200px);
  }
`;

export const AgendaHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
  }
`;

export const BackButton = styled(Button)`
  margin-right: 10px;
`;

export const DateRangeSelector = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

export const DateInput = styled(Input)`
  width: 150px;
  margin-right: 10px;

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

export const QuickSelectButton = styled(Button)`
  margin-right: 10px;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 10px;
    width: 100%;
  }
`;

export const AgendaGrid = styled.div`
  display: grid;
  grid-template-columns: 120px 120px 1fr;
  gap: 10px;
  align-items: center;
  padding: 10px;
  background-color: #f7fafc;
  font-weight: bold;
  border-bottom: 2px solid #e2e8f0;

  @media (max-width: 768px) {
    grid-template-columns: 100px 100px 1fr;
    font-size: 12px;
  }
`;

export const AgendaItemGrid = styled.div<{ color: string }>`
  display: grid;
  grid-template-columns: 120px 120px 1fr;
  gap: 10px;
  align-items: center;
  padding: 10px;
  border-left: 4px solid ${props => props.color};
  background-color: ${props => `${props.color}10`};
  transition: background-color 0.2s ease;
  cursor: pointer;

  &:hover {
    background-color: ${props => `${props.color}20`};
  }

  @media (max-width: 768px) {
    grid-template-columns: 100px 100px 1fr;
    font-size: 12px;
  }
`;

export const AgendaScrollContainer = styled.div`
  overflow-y: auto;
  flex-grow: 1;
`;

export const AgendaDate = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const AgendaDateContent = styled.div<{ isOpen: boolean }>`
  display: ${props => props.isOpen ? 'block' : 'none'};
`;

export const AgendaTime = styled.span`
  font-weight: bold;
  white-space: nowrap;
`;

export const AgendaTitle = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AgendaConvenio = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CalendarToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
  }
`;

export const CalendarToolbarButton = styled(Button)`
  margin: 0 5px;

  @media (max-width: 768px) {
    margin: 5px 0;
    width: 100%;
  }
`;

export const CalendarHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const StyledTabs = styled(Tabs)`
  margin: 0 auto;
`;

export const StyledTab = styled(Tab)`
  font-weight: 600;
  color: #4a5568;
  padding: 8px 16px;
  transition: color 0.2s ease-in-out;

  &[aria-selected="true"] {
    color: #3182ce;
  }

  &:hover {
    color: #3182ce;
  }
`;


const glassmorphismStyle = css`
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
`;

export const AnotacoesContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
`;

export const AnotacoesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const AnotacoesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  overflow-y: auto;
  padding: 10px;
`;

export const NovaAnotacaoCard = styled.div<{ isCreating: boolean; cor: string }>`
  ${glassmorphismStyle}
  background-color: ${props => props.cor};
  border-radius: 12px;
  padding: 20px;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: ${props => props.isCreating ? 'flex-start' : 'center'};
  align-items: ${props => props.isCreating ? 'stretch' : 'center'};
  white-space: pre-wrap;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
  }
`;

export const AnotacaoCard = styled.div<{ cor: string }>`
  ${glassmorphismStyle}
  background-color: ${props => props.cor};
  border-radius: 12px;
  padding: 20px;
  min-height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  white-space: pre-wrap;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

export const StyledColorPicker = styled(ChromePicker)`
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 2;
  ${glassmorphismStyle}
  border-radius: 8px;
`;

export const StyleButton = styled.button<{ active: boolean }>`
  background-color: ${props => props.active ? 'rgba(0, 0, 0, 0.1)' : 'transparent'};
  border: none;
  padding: 8px;
  margin-right: 8px;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.2s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export const StyleButtonsContainer = styled.div`
  display: flex;
  margin-bottom: 15px;
`;

export const AnotacaoTitulo = styled.h3`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  border: none;
  background: transparent;
  width: 100%;
  font-family: 'Inter', sans-serif;
`;

export const AnotacaoTexto = styled.textarea`
  font-size: 16px;
  flex-grow: 1;
  border: none;
  background: transparent;
  resize: none;
  width: 100%;
  min-height: 120px;
  margin-bottom: 15px;
  font-family: 'Inter', sans-serif;
`;

export const FooterControls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
`;

export const ColorPickerButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  color: #4a5568;
  position: relative;
  transition: all 0.2s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

export const SaveButton = styled.button`
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  color: white;
  padding: 8px 16px;
  border-radius: 8px;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  }
`;

export const FloatingLabel = styled.div`
  position: relative;
  margin-bottom: 20px;

  span {
    position: absolute;
    top: -10px;
    left: 10px;
    background: white;
    padding: 0 5px;
    font-size: 12px;
    color: #4a5568;
    transition: all 0.2s ease;
  }
`;

export const GlassmorphicInput = styled(Input)`
  ${glassmorphismStyle}
  background: rgba(255, 255, 255, 0.1);
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  color: #2d3748;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.6);
  }

  &::placeholder {
    color: #a0aec0;
  }
`;

export const GlassmorphicSelect = styled(Select)`
  ${glassmorphismStyle}
  background: rgba(255, 255, 255, 0.1);
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  color: #2d3748;
  transition: all 0.3s ease;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.6);
  }
`;

export const GlassmorphicDatePicker = styled.input`
  ${glassmorphismStyle}
  background: rgba(255, 255, 255, 0.1);
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  color: #2d3748;
  transition: all 0.3s ease;
  border-radius: 8px;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.6);
  }

  &::-webkit-calendar-picker-indicator {
    filter: invert(0.5);
    cursor: pointer;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
`;

export const AnotacaoFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  font-size: 14px;
  color: #4a5568;
`;

export const AnotacaoData = styled.span`
  font-style: italic;
`;

export const AnotacaoRelevancia = styled.span`
  font-weight: bold;
  text-transform: uppercase;
`;

// Add responsive styles
const media = {
  desktop: '@media(min-width: 1024px)',
  tablet: '@media(min-width: 768px) and (max-width: 1023px)',
  mobile: '@media(max-width: 767px)',
};

export const ResponsiveAnotacoesGrid = styled(AnotacoesGrid)`
  ${media.desktop} {
    grid-template-columns: repeat(3, 1fr);
  }

  ${media.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${media.mobile} {
    grid-template-columns: 1fr;
  }
`;

// Add dark mode styles
export const DarkModeContainer = styled(AnotacoesContainer)`
  background: linear-gradient(135deg, #2d3748 0%, #1a202c 100%);
  color: #e2e8f0;
`;

export const DarkModeCard = styled(AnotacaoCard)`
  background-color: rgba(45, 55, 72, 0.7);
  color: #e2e8f0;
`;

export const DarkModeInput = styled(GlassmorphicInput)`
  background: rgba(45, 55, 72, 0.3);
  color: #e2e8f0;

  &::placeholder {
    color: #a0aec0;
  }
`;

export const DarkModeSelect = styled(GlassmorphicSelect)`
  background: rgba(45, 55, 72, 0.3);
  color: #e2e8f0;
`;

export const DarkModeDatePicker = styled(GlassmorphicDatePicker)`
  background: rgba(45, 55, 72, 0.3);
  color: #e2e8f0;

  &::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
`;