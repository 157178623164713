import React, { useState, useEffect } from 'react';
import {
  ClinicaContainer,
  TopContainer,
  BottomContainer,
  Form,
  FormField,
  ControlButtons,
  FormRow,
  HeaderContainer,
  SectionTitle,
  StyledInput,
  StyledLabel,
  ErrorMessage,
  CardWrapper,
  ClinicName,
  EditIcon,
  SaveIcon,
  CancelIcon,
  StepIndicator,
  Step,
  StepTitle,
  SectionIcon,
  WelcomePreview,
  ChatBubble,
} from './ClinicaModule.styles';
import {
  fetchClinicaById,
  updateClinica,
  Clinica as ClinicaType,
  UpdateClinicaDto,
} from 'services/clientesService';
import CustomButton from 'components/CustomButton';
import { ViaCEPService } from 'services/viacep.service';
import { cnpjRegex, phoneRegex, cepRegex } from 'helpers/regex.helper';
import { useAuth } from 'context/authContext';
import { Progress, Tooltip } from '@chakra-ui/react';
import { InfoIcon } from '@chakra-ui/icons';

const steps = [
  'Informações Básicas',
  'Horário de Funcionamento',
  'Boas-Vindas',
  'Endereço',
];

const ClinicaModule: React.FC = () => {
  const { userData, isAuthenticated } = useAuth();
  const [clinica, setClinica] = useState<ClinicaType | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    const loadClinica = async () => {
      if (!userData?.id_clinica || !isAuthenticated) {
        console.warn('Dados do usuário não encontrados no contexto ou usuário não autenticado.');
        return;
      }

      try {
        const data = await fetchClinicaById(userData.id_clinica);
        setClinica(data);
      } catch (error: any) {
        console.error('Failed to load clinica:', error);
      }
    };

    if (isAuthenticated) {
      loadClinica();
    }
  }, [userData, isAuthenticated]);

  const handleEditClinica = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setErrors({});
  };

  const handleSave = async (e: React.FormEvent) => {
    e.preventDefault();
    if (clinica) {
      try {
        const updateData: UpdateClinicaDto = {
          ...clinica,
          expediente_dias: clinica.expediente_dias,
          expediente_horarios: clinica.expediente_horarios,
          welcome: clinica.welcome,
        };

        if (clinica.id_clinica !== undefined) {
          const updatedClinica = await updateClinica(clinica.id_clinica.toString(), updateData);
          setClinica(updatedClinica);
        }
        setIsEditing(false);
      } catch (error: any) {
        console.error('Failed to save clinica:', error);
        alert('Falha ao salvar as informações da clínica. Veja o console para detalhes.');
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (clinica) {
      const { name, value } = e.target;
      setClinica({ ...clinica, [name]: value });

      if (name === 'cnpj' && !cnpjRegex.test(value)) {
        setErrors({ ...errors, cnpj: 'Formato de CNPJ inválido. Use 00.000.000/0000-00' });
      } else if (name === 'telefone_clinica' && !phoneRegex.test(value)) {
        setErrors({ ...errors, telefone_clinica: 'Formato de telefone inválido. Use (00) 00000-0000' });
      } else if (name === 'cep' && !cepRegex.test(value)) {
        setErrors({ ...errors, cep: 'Formato de CEP inválido. Use 00000-000' });
      } else {
        setErrors({ ...errors, [name]: '' });
      }
    }
  };

  const handleCEPBlur = async () => {
    if (clinica?.cep && cepRegex.test(clinica.cep)) {
      try {
        const data = await ViaCEPService.getAddressByCEP(clinica.cep);
        if (data && data.logradouro) {
          setClinica({
            ...clinica,
            logradouro: data.logradouro || '',
            bairro: data.bairro || '',
            localidade: data.localidade || '',
            uf: data.uf || '',
            ibge: data.ibge || '',
          });
          setErrors({ ...errors, cep: '' }); // Limpa o erro do CEP se for válido
        } else {
          setErrors({ ...errors, cep: 'CEP não encontrado. Verifique o número e tente novamente.' });
        }
      } catch (error) {
        console.error('Erro ao buscar endereço pelo CEP:', error);
        setErrors({ ...errors, cep: 'Erro ao buscar o CEP. Certifique-se de que o CEP está correto e tente novamente.' });
      }
    } else {
      setErrors({ ...errors, cep: 'Formato de CEP inválido. Use 00000-000' });
    }
  };

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  // Adicione esta nova função para lidar com cliques nas abas
  const handleStepClick = (stepIndex: number) => {
    setCurrentStep(stepIndex);
  };

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <>
            <SectionTitle>
              <SectionIcon name="info" />
              Informações Básicas
            </SectionTitle>
            <FormRow>
              <FormField>
                <StyledLabel>Nome da Clínica</StyledLabel>
                <StyledInput
                  type="text"
                  name="nome_clinica"
                  value={clinica?.nome_clinica || ''}
                  onChange={handleChange}
                  disabled={!isEditing}
                />
              </FormField>
              <FormField>
                <StyledLabel>Nome do Chatbot</StyledLabel>
                <StyledInput
                  type="text"
                  name="nome_do_chatbot"
                  value={clinica?.nome_do_chatbot || ''}
                  onChange={handleChange}
                  disabled={!isEditing}
                />
              </FormField>
            </FormRow>
            <FormRow>
              <FormField>
                <StyledLabel>CNPJ</StyledLabel>
                <StyledInput
                  type="text"
                  name="cnpj"
                  value={clinica?.cnpj || ''}
                  onChange={handleChange}
                  placeholder="00.000.000/0000-00"
                  maxLength={18}
                  disabled={!isEditing}
                />
                {errors.cnpj && <ErrorMessage>{errors.cnpj}</ErrorMessage>}
              </FormField>
              <FormField>
                <StyledLabel>Telefone</StyledLabel>
                <StyledInput
                  type="text"
                  name="telefone_clinica"
                  value={clinica?.telefone_clinica || ''}
                  onChange={handleChange}
                  placeholder="(00) 00000-0000"
                  disabled={!isEditing}
                />
                {errors.telefone_clinica && <ErrorMessage>{errors.telefone_clinica}</ErrorMessage>}
              </FormField>
              <FormField>
                <StyledLabel>Site</StyledLabel>
                <StyledInput
                  type="text"
                  name="site_clinica"
                  value={clinica?.site_clinica || ''}
                  onChange={handleChange}
                  disabled={!isEditing}
                />
              </FormField>
            </FormRow>
          </>
        );
      case 1:
        return (
          <>
            <SectionTitle>
              <SectionIcon name="clock" />
              Horário de Funcionamento
            </SectionTitle>
            <FormRow>
              <FormField>
                <StyledLabel>Expediente Dias</StyledLabel>
                <StyledInput
                  type="text"
                  name="expediente_dias"
                  value={clinica?.expediente_dias || ''}
                  onChange={handleChange}
                  disabled={!isEditing}
                />
              </FormField>
              <FormField>
                <StyledLabel>Expediente Horários</StyledLabel>
                <StyledInput
                  type="text"
                  name="expediente_horarios"
                  value={clinica?.expediente_horarios || ''}
                  onChange={handleChange}
                  disabled={!isEditing}
                />
              </FormField>
            </FormRow>
          </>
        );
      case 2:
        return (
          <>
            <SectionTitle>
              <SectionIcon name="smile" />
              Boas-Vindas
              <Tooltip label="Personalize a mensagem que o chatbot usará para receber os pacientes." aria-label="Tooltip">
                <InfoIcon ml={2} />
              </Tooltip>
            </SectionTitle>
            <FormRow>
              <FormField>
                <StyledLabel>
                  Mensagem de Boas-Vindas
                  <Tooltip label="Escreva uma mensagem acolhedora que o chatbot irá utilizar para cumprimentar os pacientes." aria-label="Tooltip">
                    <InfoIcon ml={2} />
                  </Tooltip>
                </StyledLabel>
                <StyledInput
                  type="text"
                  name="welcome"
                  value={clinica?.welcome || ''}
                  onChange={handleChange}
                  disabled={!isEditing}
                />
              </FormField>
            </FormRow>
            <WelcomePreview>
              <StyledLabel>Pré-visualização:</StyledLabel>
              <ChatBubble>
                {clinica?.welcome || 'Sua mensagem de boas-vindas aparecerá aqui.'}
              </ChatBubble>
            </WelcomePreview>
          </>
        );
      case 3:
        return (
          <>
            <SectionTitle>
              <SectionIcon name="map-pin" />
              Endereço
            </SectionTitle>
            <FormRow>
              <FormField>
                <StyledLabel>CEP*</StyledLabel>
                <StyledInput
                  type="text"
                  name="cep"
                  value={clinica?.cep || ''}
                  onChange={handleChange}
                  onBlur={handleCEPBlur}
                  placeholder="00000-000"
                  maxLength={10}
                  disabled={!isEditing}
                  required
                />
                {errors.cep && <ErrorMessage>{errors.cep}</ErrorMessage>}
              </FormField>
              <FormField>
                <StyledLabel>Logradouro</StyledLabel>
                <StyledInput
                  type="text"
                  name="logradouro"
                  value={clinica?.logradouro || ''}
                  onChange={handleChange}
                  disabled={!isEditing}
                />
              </FormField>
              <FormField>
                <StyledLabel>Complemento</StyledLabel>
                <StyledInput
                  type="text"
                  name="complemento"
                  value={clinica?.complemento || ''}
                  onChange={handleChange}
                  disabled={!isEditing}
                />
              </FormField>
            </FormRow>
            <FormRow>
              <FormField>
                <StyledLabel>Bairro</StyledLabel>
                <StyledInput
                  type="text"
                  name="bairro"
                  value={clinica?.bairro || ''}
                  onChange={handleChange}
                  disabled={!isEditing}
                />
              </FormField>
              <FormField>
                <StyledLabel>Cidade</StyledLabel>
                <StyledInput
                  type="text"
                  name="localidade"
                  value={clinica?.localidade || ''}
                  onChange={handleChange}
                  disabled={true}
                />
              </FormField>
              <FormField>
                <StyledLabel>UF</StyledLabel>
                <StyledInput
                  type="text"
                  name="uf"
                  value={clinica?.uf || ''}
                  maxLength={2}
                  onChange={handleChange}
                  disabled={true}
                />
              </FormField>
            </FormRow>
            {/* Campo IBGE oculto */}
            <input
              type="hidden"
              name="ibge"
              value={clinica?.ibge || ''}
            />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <ClinicaContainer>
      <TopContainer>
        <HeaderContainer>
          <CardWrapper>
            <ClinicName>{clinica?.nome_clinica || 'Nome não disponível'}</ClinicName>
            {!isEditing && (
              <CustomButton
                text="Editar"
                colorScheme="blue"
                onClick={handleEditClinica}
                icon={<EditIcon />}
              />
            )}
          </CardWrapper>
        </HeaderContainer>
      </TopContainer>
      <BottomContainer>
        <StepIndicator>
          {steps.map((step, index) => (
            <Step 
              key={index} 
              isActive={currentStep === index}
              onClick={() => handleStepClick(index)}
              style={{ cursor: 'pointer' }}
            >
              <StepTitle>{step}</StepTitle>
            </Step>
          ))}
        </StepIndicator>
        <Form onSubmit={handleSave}>
          <Progress value={((currentStep + 1) / steps.length) * 100} mb={4} />

          {renderStepContent(currentStep)}

          <ControlButtons>
            {currentStep > 0 && (
              <CustomButton text="Voltar" colorScheme="gray" onClick={handleBack} />
            )}
            {currentStep < steps.length - 1 && (
              <CustomButton text="Próximo" colorScheme="blue" onClick={handleNext} />
            )}
            {isEditing && (
              <>
                <CustomButton
                  text="Salvar"
                  colorScheme="green"
                  type="submit"
                  icon={<SaveIcon />}
                />
                <CustomButton
                  text="Cancelar"
                  colorScheme="red"
                  onClick={handleCancel}
                  icon={<CancelIcon />}
                />
              </>
            )}
          </ControlButtons>
        </Form>
      </BottomContainer>
    </ClinicaContainer>
  );
};

export default ClinicaModule;